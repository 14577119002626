import { Button, Group, Image, UnstyledButton } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from './card.module.css';
import { Link } from "react-router-dom";
import {
  faAward,
  faInfoCircle
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { ICourse } from "../../interfaces/ICourse.tsx";



export default function CourseCard(props: { c: ICourse, style: any }) {
  const { c, style } = props;

  return (
      <UnstyledButton className={classes.item} style={{ ...style }}>
        <Image src={c.image} mb={'md'} alt={c.name}/>
        {c.unlocked ?
            <Button variant="light"
                    component={Link}
                    leftSection={c.completed ?
                        <FontAwesomeIcon
                            icon={faAward}/> : null}
                    to={`/proCourses/${c.id}`}
                    color="blue"
                    fullWidth
                    radius="md">
              {c.completed ? 'Completato' : 'Vai al corso'}
            </Button>
            :
            <Group>
              <Button
                  leftSection={<FontAwesomeIcon
                      icon={faInfoCircle}/>}
                  variant="outline"
                  fullWidth
                  onClick={() => {
                    window.open(`${import.meta.env.VITE_WEBSITE_URL}/it/corso/${c.slug}`, '_blank', 'noopener,noreferrer')
                  }}
                  radius="md">
                Più informazioni
              </Button>
            </Group>
        }
      </UnstyledButton>
  )
}