import {
  Button,
  Card,
  Flex,
  Group,
  Modal,
  Radio,
  RadioGroup,
  Stepper,
  Text, Title
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from "@mantine/form";
import PerformanceStep from "./configurations/Performance.step.tsx";
import LifeSuiteStep from "./configurations/LifeSuite.step.tsx";
import ExposureStep from "./configurations/Exposure.step.tsx";
import { FormProvider } from "./FormContext.tsx";
import MapUserAndDevice from "./configurations/MapUserAndDevice.tsx";
import { maps } from "../data/maps.tsx";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { formatDate } from "../utils/formatDate.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLink
} from "@fortawesome/pro-light-svg-icons/faExternalLink";
import { faLock } from "@fortawesome/pro-light-svg-icons/faLock";

export const CreateConfigurationModal = (
    {
      opened, close, callback, myProducts
    }: any) => {

  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [inProgress, setInProgress] = useState<boolean>(false)

  const form = useForm({
    mode: 'controlled',
    initialValues: {
      type: '',
      patientId: '',
      deviceId: '',
      map: '',
      steps: []
    },
  });

  useEffect(() => {
    setInProgress(false)
    if ( !opened ) {
      setActive(0)
      form.reset();
    }
  }, [opened])

  const onSubmit = (values: any) => {
    console.log('submit in progress', values)
    const d: any = {};
    d.deviceId = values.deviceId;
    d.patientId = values.patientId;
    d.type = values.type;
    d.map = values.map;
    if ( ['wellbeing', 'psychology', 'corporate', 'situations',].includes(values.type)) {
      d.config = JSON.stringify(values.steps)
    }
    if ( values.type === 'performance' ) {
      d.config = JSON.stringify({
        variation: values.variation,
        duration: values.duration
      })
    }

    setInProgress(true);

    fetch(`${import.meta.env.VITE_API_URL}/services/configurations`, {
      method: "POST",
      body: JSON.stringify(d),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .then(() => {
          console.info('callback')
          callback();
          close();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false);
        });
  }

  const isButtonDisabled = (): boolean => {
    const { type, deviceId, patientId, map } = form.getValues();

    if ( active === 0 && type === '' ) {
      return true;
    }

    if ( active === 1 ) {
      if ( deviceId === '' || patientId === '' ) {
        return true;
      }

      // Check if `maps[type]` is a non-empty array and validate `map`
      if ( Array.isArray(maps[type]) && maps[type].length > 0 ) {
        return !maps[type].some((i: any) => i.value === map);
      }
    }

    return active === 2 || inProgress;


  };


  const cards = [
    { value: 'exposure', label: 'Augmented Food Exposure', g1: '#836d4b', g2: '#a68753', slug: 'https://discoverbecome.com/it/applicazione/augmented-exposure'  },
    { value: 'performance', label: 'Augmented Performance',g1: '#f17333', g2: '#fd8d1d', slug: 'https://discoverbecome.com/it/applicazione/augmented-performance'    },
    { value: 'wellbeing', label: 'Augmented Wellbeing', g1: '#679a59', g2: '#8fb224', slug: 'https://discoverbecome.com/it/applicazione/augmented-wellbeing-realta-virtuale-riduzione-ansia-distress'},
    { value: 'psychology', label: 'Augmented Psychology',g1: '#0179c2', g2: '#00aced', slug: 'https://discoverbecome.com/it/applicazione/augmented-psychology-realta-virtuale-pratica-clinica'},
    { value: 'corporate', label: 'Augmented Corporate', g1: '#00a5c1', g2: '#00b3ad', slug: 'https://discoverbecome.com/it/applicazione/augmented-corporate-realta-virtuale-soft-skills' },
    { value: 'situations', label: 'Augmented Situations', g1: '#736f6c', g2: '#a29c9a', slug: 'https://discoverbecome.com/it/applicazione/augmented-situations-realta-virtuale-pratica-clinica' },
  ].map((item) => {
    const product = myProducts.find((p: any) => p.name === item.label);
    return (
        product ? <Radio.Card radius="md"
                              style={{height: 120, maxWidth: 225}}
                              bg={`linear-gradient(to right, ${item.g1}, ${item.g2})`}
                              p={'md'}
                              value={item.value}
                              key={item.value}>
              <Group wrap="nowrap" align="flex-start" flex={1}>
                <Radio.Indicator/>
                <Flex align={{ base: 'flex-start', lg: 'center' }}
                      direction={{ base: 'column', lg: 'row' }}
                      gap={'sm'}
                      justify={'center'}>
                  <Title order={4} c={'white'}>{item.label}</Title>
                </Flex>
              </Group>
              <Text mt={'md'} c={'white'}>Until: {formatDate(product.until_date)}</Text>
            </Radio.Card> :
            <Card radius="md"
                  style={{width: '100%', height: 120, maxWidth: 225}}
                  bg={`linear-gradient(to right, ${item.g1}, ${item.g2})`}
                  withBorder
                  key={item.value}
                  p={'sm'}>
              <Group wrap="nowrap" align="flex-start" flex={1}>
                <Flex
                      direction={{ base: 'column', lg: 'row' }}
                      gap={'sm'}
                      justify={'center'}>
                  <FontAwesomeIcon icon={faLock} style={{marginTop: 6}}/>
                  <Title order={4} c={'white'}>{item.label}</Title>
                </Flex>
              </Group>
              <Button fullWidth
                      color={'white'}
                      variant={'outline'}
                      size={'xs'}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.slug, '_blank', 'noopener,noreferrer')
                      }}
                      mt={'md'} rightSection={<FontAwesomeIcon
                  icon={faExternalLink}/>}>Unlock</Button>
            </Card>
    )
  });

  return (

      <Modal opened={opened} onClose={close} size={'xl'}
             closeOnClickOutside={false}
             title={t('ccm_create_session', 'Crea una nuova sessione')}>
        <FormProvider form={form}>
          <form
              onKeyDown={(event) => {
                if ( event.key === "Enter" ) {
                  event.preventDefault(); // Prevent Enter key from submitting
                }
              }}
              onSubmit={form.onSubmit(onSubmit)}>
            <Stepper active={active}
                     orientation={isMobile ? 'vertical' : 'horizontal'}>
              <Stepper.Step label="App" description="Seleziona l'app">
                <RadioGroup  {...form.getInputProps('type')}>
                  <Flex justify={'space-evenly'}
                        mt={'md'}
                        wrap={'wrap'}
                        mb={'md'}
                        gap={'md'}
                        align={'center'}>
                    {cards}
                  </Flex>
                </RadioGroup>
              </Stepper.Step>
              <Stepper.Step label={t('ccm_settings', "Impostazioni")}
                            description={t('ccm_settings_d', "Seleziona scenario, utente e dispositivo")}>
                <MapUserAndDevice/>
              </Stepper.Step>
              <Stepper.Step label={t('ccm_params', "Parametri")}
                            description={t('ccm_params_d', "Modifica i parametri")}>
                {
                    form.getValues().type === 'performance' &&
                    <PerformanceStep/>
                }
                {
                    form.getValues().type === 'exposure' && <ExposureStep/>
                }
                {
                  ['wellbeing', 'psychology', 'corporate', 'situations',].includes(form.getValues().type) && <LifeSuiteStep/>
                }
              </Stepper.Step>
            </Stepper>

            <Group justify="center" mt="xl">
              {active > 0 &&
                  <Button variant="default"
                          onClick={prevStep}>{t('back', 'Indietro')}</Button>
              }

              {active < 2 && <Button
                  disabled={isButtonDisabled()}
                  type={'button'}
                  onClick={nextStep}>{t('next', 'Avanti')}</Button>
              }

              {active === 2 && <Button
                  loading={inProgress}
                  disabled={active < 2 || inProgress}
                  type={'submit'}
              >{t('save', 'Salva')}</Button>}
            </Group>
          </form>
        </FormProvider>

      </Modal>
  )
}