import GenericListComponent from "./GenericListComponent.tsx";

export default function AdminCourseChapters() {

  return (

      <GenericListComponent
          name={'courseChapter'}
          columns={[
            { accessor: 'order', title: 'Order'},
            { accessor: 'title', title: 'Title'},
            { accessor: 'description', title: 'Description' },
            { accessor: 'video_url', title: 'Url' },
            { accessor: 'duration', title: 'Duration' },
          ]}
      />
  );
}