import {
  ActionIcon,
  Autocomplete,
  Collapse,
  Flex, JsonInput,
  NumberInput, Textarea,
  TextInput
} from "@mantine/core";
import { useFormContext } from "../../components/FormContext.tsx";
import { useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSync
} from "@awesome.me/kit-528b119969/icons/duotone/solid";


export const DynamicForm = ({ name, type }: any) => {


  const form = useFormContext();

  const field = name.replace('rel_', '');
  const modelName = field.replace('_id', '');
  const [showSearch, setShowSearch] = useState<boolean>(false)


  const replacedRelation = type.replace('relation:', '').split(',');

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [debounced] = useDebouncedValue(value, 400);

  const fetchResource = async () => {
    const fId = form.getValues()[field];
    if ( fId && data.find((d: any) => d.id === fId) ) {
      return // dont fetch again
    }
    if ( value.trim().length < 3 ) return;
    setLoading(true);
    let url = `${import.meta.env.VITE_API_URL}/services/admin/resource/${modelName}?&value=${value}`
    try {
      const response = await fetch(url,
          {
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
      const result = await response.json();
      console.log('res data is', result.data)
      setData(result.data); // Assuming API returns { data, total }
    } catch ( error ) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when page changes
  useEffect(() => {
    fetchResource();

  }, [debounced]);

  useEffect(() => {
    return () => {
      setValue('')
      setData([])
    }
  }, [])



  return <>

    {
        type === 'string' &&
        <TextInput {...form.getInputProps(name)} label={name}/>
    }

    {
        type === 'number' &&
        <NumberInput {...form.getInputProps(name)} label={name}/>
    }

    {
        type === 'text' &&
        <Textarea
            autosize
            minRows={4}
            {...form.getInputProps(name)} label={name}/>
    }

    {
        type === 'json' &&
        <JsonInput
            {...form.getInputProps(name)}
            autosize
            minRows={4}
            label={name}/>
    }

    {
      type.includes('relation:') ? (
          <>
            <Flex justify={'flex-end'}>
              <ActionIcon
                  onClick={() => setShowSearch(!showSearch)}><FontAwesomeIcon
                  icon={faSearch}/></ActionIcon>
            </Flex>
            <Collapse in={showSearch}>
              <Autocomplete
                  clearable
                  leftSection={loading ?
                      <FontAwesomeIcon icon={faSync} spin/> : null}
                  value={value}
                  onChange={(val) => {
                    setValue(val);
                    const selected = data.find((item: any) => {
                      // Build the composite label exactly as you do for the data prop
                      const compositeLabel = replacedRelation.map((key: string) => item[key]).join(" ");
                      return compositeLabel === val;
                    });
                    console.log('selected is', selected, 'field is', field, 'value is', selected?.id)
                    if ( selected ) {
                      form.setFieldValue(field, selected.id);
                    } else {
                      // Optionally, clear the field if there's no match
                      form.setFieldValue(field, '');
                    }
                  }}

                  label={modelName}
                  placeholder="Insert at least 3 characters to start the search"
                  data={data.map((u: any) => replacedRelation.map((key: string) => u[key]).join(" "))}
              />
            </Collapse>
          </>
      ) : null
    }

  </>
}