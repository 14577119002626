import {
  useDocumentTitle,
  useFavicon,
  useFetch,
} from "@mantine/hooks";
import { ICourse } from "../interfaces/ICourse.tsx";
import {
  Button,
  Card,
  Divider,
   Grid,
  Image,
  Loader,
  Title,
  Transition
} from "@mantine/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useEffect, useState } from "react";
import { faEye } from "@fortawesome/pro-light-svg-icons/faEye";


export function FreeCourses() {
  useDocumentTitle('BECOME | Tutti i corsi liberi');
  useFavicon('/favicon.ico');
  const [mountedCards, setMountedCards] = useState<number[]>([]);
  const { data, loading } = useFetch<{data: ICourse[]}>(
      `${import.meta.env.VITE_API_URL}/services/userCourses`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  useEffect(() => {
    if (data?.data) {
      data.data.filter(i => i.ecm === null).forEach((_, index) => {
        setTimeout(() => {
          setMountedCards((prev) => [...prev, index]);
        }, 50 * index); // Add a delay of 50ms per card
      });
    }
  }, [data]);

  return (
      <>
        <Title>Piattaforma E-Learning | Corsi liberi</Title>
        <Divider mt={'xs'}/>
        <Grid gutter={{ base: 5, xs: 'md', md: 'xl' }} mt={'md'}  style={{minHeight: 150}}>
          { loading ?<Grid.Col span={12} ta={'center'}><Loader/></Grid.Col>  :
              data?.data?.filter(i => i.ecm === null).map((c: ICourse, index: number) => (
                  <Transition
                      key={c.id}
                      transition="fade-left"
                      duration={240}
                      timingFunction="ease"
                      onEnter={() => console.info('transition enter', index)}
                      mounted={mountedCards.includes(index)}
                  >
                    {(styles) => (
                        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                        <Card withBorder
                              style={styles} // Apply styles provided by Transition
                              shadow="sm"
                              padding="xs"
                              radius="md"
                        >
                          <Card.Section
                              mb={'xs'}
                              style={{ position: 'relative' }}>
                            <Image src={c.image}
                                   alt={c.name}/>
                          </Card.Section>

                          {c.unlocked ?
                              <Button variant="light"
                                      component={Link}
                                      leftSection={c.completed ?         <FontAwesomeIcon
                                          icon={faAward}/> : null}
                                      to={`/proCourses/${c.id}`}
                                      color="blue"
                                      fullWidth
                                      radius="md">
                                {c.completed ? 'Completato' : 'Vai al corso'}
                              </Button>
                              :
                              <Button
                                  leftSection={<FontAwesomeIcon icon={faEye}/>}
                                  fullWidth
                                  variant="light"
                                  color="violet"
                                  onClick={() => {
                                    window.open(c.slug, '_blank', 'noopener,noreferrer')
                                  }}
                                  radius="md">
                                Più informazioni
                              </Button>
                          }
                        </Card>
                        </Grid.Col>
                    )}
                  </Transition>
              ))
          }
        </Grid>
      </>
  )
}