import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { DataTable } from 'mantine-datatable';
import { ActionIcon, Flex, TextInput } from '@mantine/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faRefresh,
  faSearch
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useDisclosure } from '@mantine/hooks';
import { ResourceModal } from "./components/Resource.modal.tsx";
import { useSearchParams } from "react-router-dom";


const PAGE_SIZES = [10, 15, 20];


const GenericListComponent = forwardRef((
    {
      name,
      columns,
      children,
      onRowClick = null
    }: any, ref) => {

  const [data, setData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState(''); // User input state
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
  const [selectedRecord, setSelectedRecord] = useState<any>()
  const [opened, { open, close }] = useDisclosure(false);

  const [searchParams] = useSearchParams();
  const fullQueryString = searchParams.toString(); // Always up-to-date
  const [finalQuery, setFinalQuery] = useState(''); // Stable debounced state for API call


  const latestFetchParams = useRef({ finalQuery, page, pageSize });
  const fetchTimeout = useRef<NodeJS.Timeout | null>(null);

  // **Expose setSelectedRecord to the Parent**
  useImperativeHandle(ref, () => ({
    setSelectedRecord,
  }));

  // Function to fetch data
  const fetchData = async () => {
    console.info('calling fetch data');
    setLoading(true);
    let url = `${import.meta.env.VITE_API_URL}/services/admin/resource/${name}?page=${page}&size=${pageSize}`
    if (finalQuery.trim().length > 0) {
      url += `&query=${encodeURIComponent(finalQuery)}`;
    }


    try {
      const response = await fetch(url,
          {
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
      const result = await response.json();
      setData(result.data);
      setTotalRecords(result.pagination.totalRecords);
    } catch ( error ) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const callback = (item: any, isUpdate = false) => {
    console.info('callback', item, isUpdate)
    if ( isUpdate ) {
      setData(data.map(r => r.id === item.id ? item : r));
    } else {
      setData([item, ...data])
    }
    close();
  }

  useEffect(() => {
    setSearchValue(fullQueryString); // Dynamically update `value` whenever `searchParams` changes
  }, [fullQueryString]); // Depend on fullQueryString to ensure updates

  useEffect(() => {
    if ( selectedRecord ) {
      open();
    }
  }, [selectedRecord])

  useEffect(() => {
    if ( !opened ) {
      setSelectedRecord(undefined)
    }
  }, [opened])

  // **Debounce search input (Wait before updating `finalQuery`)**
  useEffect(() => {
    if (fetchTimeout.current) {
      clearTimeout(fetchTimeout.current);
    }

    fetchTimeout.current = setTimeout(() => {
      setFinalQuery(searchValue || ""); // Update `finalQuery` only when user stops typing
    }, 400);

    return () => {
      if (fetchTimeout.current) {
        clearTimeout(fetchTimeout.current);
      }
    };
  }, [searchValue]); // Only trigger when `searchValue` changes

  // **Trigger API fetch when `finalQuery` is stable**
  useEffect(() => {
    const shouldFetch =
        latestFetchParams.current.finalQuery !== finalQuery ||
        latestFetchParams.current.page !== page ||
        latestFetchParams.current.pageSize !== pageSize;

    if (shouldFetch || finalQuery === "") {
      latestFetchParams.current = { finalQuery, page, pageSize };
      fetchData();
    }
  }, [finalQuery, page, pageSize]); // Only trigger when all values are fully updated

  return (
      <div>
        <ResourceModal opened={opened}
                       close={close}
                       name={name}
                       callback={callback}
                       selectedRecord={selectedRecord}/>

        <Flex justify={'space-between'} align={'center'} mb={'md'} gap={'md'}>

          <TextInput placeholder={'Search'}
                     rightSection={<FontAwesomeIcon icon={faSearch}/>}
                     rightSectionPointerEvents={'none'}
                     flex={1}
                     value={searchValue}
                     onChange={(event) => setSearchValue(event.currentTarget.value)}/>

          <ActionIcon onClick={() => fetchData()} size={'lg'}>
            <FontAwesomeIcon icon={faRefresh}/>
          </ActionIcon>
          <ActionIcon onClick={() => open()} size={'lg'}>
            <FontAwesomeIcon icon={faPlus}/>
          </ActionIcon>
        </Flex>
        {children}
        <DataTable
            fetching={loading}
            minHeight={400}
            withColumnBorders
            highlightOnHover
            onRowClick={({ record }) => onRowClick !== null ? onRowClick(record) : setSelectedRecord(record)}
            records={data}
            columns={columns}
            totalRecords={totalRecords}
            recordsPerPage={pageSize}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
  );
});

export default GenericListComponent;
