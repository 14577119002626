import { useFormContext } from "../FormContext.tsx";
import { useDisclosure, useFetch } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Drawer,
  Flex,
  Group,
  Select,
  Stack,
  Text,
  TextInput
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLoader,
  faSync
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { maps } from "../../data/maps.tsx";
import { useTranslation } from "react-i18next";

export default function MapUserAndDevice() {
  const { t } = useTranslation();
  const form = useFormContext();
  const [opened, { open, close }] = useDisclosure(false);
  const [code, setCode] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [inProgress, setInProgress] = useState<boolean>(false)

  const { data, loading, refetch } = useFetch<{ data: any }>(
      `${import.meta.env.VITE_API_URL}/services/getPatientsAndDevices`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  const createNewPatient = () => {

    setInProgress(true);
    fetch(`${import.meta.env.VITE_API_URL}/services/createPatient`, {
      method: "POST",
      body: JSON.stringify({
        code
      }),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .then(() => {
          refetch();
          close();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false);
        });

  }

  return <Stack mb={'md'}>
    <Drawer opened={opened} onClose={close} title="Crea nuovo utente"
            position={'right'}>
      <TextInput label={t('mu_code', 'Identificativo utente')}
                 defaultValue={searchValue}
                 value={code}
                 disabled={inProgress}
                 onChange={(event) => setCode(event.currentTarget.value)}
                 placeholder={t('mu_code_p', 'Codice oppure nome a scelta')}></TextInput>

      <Button fullWidth
              loading={inProgress}
              disabled={inProgress}
              mt={'lg'} onClick={createNewPatient}>{t('create', 'Crea')}</Button>
    </Drawer>

    {form.getValues().type !== 'life-suite' &&
    <Select
        required
        searchable
        key={'map'}
        onChange={(v) => form.setFieldValue('map', v)}
        value={form.getValues().map}
        label={t('select_scenario', "Seleziona lo scenario")}
        data={maps[form.getValues().type]}
    />}

    <Select
        required
        searchable
        searchValue={searchValue}

        key={'patientId'}
        onChange={(v) => form.setFieldValue('patientId', v)}
        value={form.getValues().patientId}

        onSearchChange={setSearchValue}
        disabled={loading}
        leftSectionPointerEvents="none"
        leftSection={loading ? <FontAwesomeIcon icon={faLoader} spin/> : null}
        rightSectionPointerEvents="all"
        rightSection={<ActionIcon onClick={refetch}><FontAwesomeIcon
            icon={faSync}/></ActionIcon>}
        label={t('select_patient', "Seleziona l'utente")}
        data={data ? data.data?.patients : []}
        nothingFoundMessage={<Flex justify={'space-between'} align={'center'}>
          <Group>
            <Text>{t('no_user_found', 'Nessun utente con questo nome:')}</Text> <Text
              fw={500}>{searchValue}</Text>
          </Group>
          <Button variant={'light'} onClick={open}>{t('create_new', 'Crea nuovo/a')}</Button>
        </Flex>}
    />

    <Select
        required
        searchable

        key={'deviceId'}
        onChange={(v) => form.setFieldValue('deviceId', v)}
        value={form.getValues().deviceId}

        disabled={loading}
        leftSectionPointerEvents="none"
        leftSection={loading ? <FontAwesomeIcon icon={faLoader} spin/> : null}
        rightSectionPointerEvents="all"
        rightSection={<ActionIcon onClick={refetch}><FontAwesomeIcon
            icon={faSync}/></ActionIcon>}
        label={t('select_device', "Seleziona il dispositivo")}
        data={data ? data.data?.devices : []}
    />

  </Stack>
}