import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Paper,
  Stack, Table,
  Tabs,
  Text,
  Title
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faChartUser,
  faCheck,
  faGraduationCap,
  faPlus,
  faRefresh,
  faShieldCheck,
  faStore,
  faTrash,
  faXmark
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { UserInfo } from "./components/UserInfo.tsx";
import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider.tsx";
import { notifications } from "@mantine/notifications";
import { DataTable } from "mantine-datatable";
import { useDisclosure } from "@mantine/hooks";
import { AddProductModal } from "./components/AddProduct.modal.tsx";
import { modals } from "@mantine/modals";
import { DatePickerInput } from "@mantine/dates";

export default function AdminUser() {

  const now = new Date()
  const { id } = useParams();
  const { authState } = useAuth();

  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState();

  const [courseData, setCourseData] = useState<any[]>([]);
  const [loadingCourseData, setLoadingCourseData] = useState<boolean>(false)

  const [productsData, setProductsData] = useState<any[]>([]);
  const [loadingProductsData, setLoadingProductsData] = useState<boolean>(false)

  const [deleteInProgress, setDeleteInProgress] = useState<number | undefined>()

  // const [securityData, setSecurityData] = useState<any[]>([]);
  // const [loadingSecurityData, setLoadingSecurityData] = useState<boolean>(false)

  const [opened, { open, close }] = useDisclosure(false);


  const fetchProductsData = () => {
    setLoadingProductsData(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${id}/products`,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => setProductsData(data.data))
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => setLoadingProductsData(false));
  }

  const fetchCourseData = () => {
    setLoadingCourseData(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${id}/courses`,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => setCourseData(data.data))
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => setLoadingCourseData(false));
  }

  const fetchUserInfo = () => {
    setLoadingUserInfo(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${id}`,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => setUserInfo(data.data))
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => setLoadingUserInfo(false));
  }

  useEffect(() => {
    if ( authState.user ) {

      fetchUserInfo();


      const permissions = authState.user.permissions ?? [];

      if ( permissions.includes('manage_courses') ) {
        fetchCourseData();
      }

      if ( permissions.includes('manage_products') ) {
        fetchProductsData();
      }

      if ( permissions.includes('manage_security') ) {
        //fetchSecurityData();
      }

    }
  }, [authState])

  const openModal = (p: any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
        <Text size="sm">
          Are you sure you want to remove this product? {p.name}
        </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => removeProduct(p.product_id),
  });


  const removeProduct = (productId: number) => {
    setDeleteInProgress(productId)
    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${id}/products/${productId}`, {
      method: "DELETE",
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setDeleteInProgress(undefined)
          callback();
        })
  }

  const updateProduct= (productId: any,  value: any) => {
    let validUntil = null;
    if(value){
      const newDate = new Date(value);
      newDate.setDate(newDate.getDate() + 1); // Add 1 day
      validUntil = newDate.toISOString().split('T')[0];
    }

    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${id}/products/${productId}`, {
      method: "PATCH",
      credentials: 'include',
      body: JSON.stringify({
        validUntil
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setDeleteInProgress(undefined)
          callback();
        })
  }


  const greaterThenNow = (isoDate: string) => {
    if ( !isoDate ) return 'default';
    const date = new Date(isoDate);
    return date > now ? 'teal' : 'red';
  }

  const callback = () => {
    close();
    fetchProductsData();
  }

  if ( !authState.user ) return <>Loading...</>


  return <>
    <UserInfo info={userInfo} loadingUserInfo={loadingUserInfo}/>

    <Tabs defaultValue="products" mt={'md'} variant="outline" radius="lg">
      <Tabs.List>

        <Tabs.Tab value="products"
                  disabled={!authState.user.permissions.includes('manage_products')}
                  leftSection={<FontAwesomeIcon icon={faStore}/>}>
          Payments - Products
        </Tabs.Tab>

        <Tabs.Tab value="courses"
                  disabled={!authState.user.permissions.includes('manage_courses')}
                  leftSection={
                    <FontAwesomeIcon
                        icon={faGraduationCap}/>}>
          Courses
        </Tabs.Tab>

        <Tabs.Tab value="security"
                  disabled={!authState.user.permissions.includes('manage_permissions')}
                  leftSection={<FontAwesomeIcon icon={faShieldCheck}/>}>
          Security
        </Tabs.Tab>

        <Tabs.Tab value="logs"
                  disabled={!authState.user.permissions.includes('dev')}
                  leftSection={<FontAwesomeIcon icon={faBug}/>}>
          Error log
        </Tabs.Tab>
        <Tabs.Tab value="analytics"
                  disabled={!authState.user.permissions.includes('analytics')}
                  leftSection={<FontAwesomeIcon icon={faChartUser}/>}>
          Analytics
        </Tabs.Tab>
      </Tabs.List>

      {
          authState.user.permissions.includes('manage_products') &&
          <Tabs.Panel value="products" mt={'md'}>
              <AddProductModal opened={opened} close={close} userId={id}
                               callback={callback}/>
              <Flex gap={'md'} direction={{ base: 'column', md: 'row' }}>
                  <Paper withBorder shadow={'md'} flex={1} p={'sm'}>
                      <Flex justify={'space-between'} align={'center'}
                            gap={'md'}>
                          <Title order={5}>Active products</Title>
                          <Button onClick={open} variant={'outline'}
                                  size={'compact-sm'}><FontAwesomeIcon
                              icon={faPlus}/></Button>
                      </Flex>
                      <DataTable
                          minHeight={350}
                          height={'auto'}
                          fetching={loadingProductsData}
                          columns={
                            [
                              { accessor: 'name' },
                              { accessor: 'category', render: ({category, experience_category}) => <>
                                  {category} {experience_category ? `- ${experience_category}` : ''}
                                </> },
                              {
                                accessor: 'until_date',
                                title: 'Valid until',
                                render: ({ product_id, until_date }) =>
                                    until_date ?
                                        <DatePickerInput value={new Date(until_date)}
                                                         onChange={(value) => updateProduct(product_id, value)}
                                                         error={greaterThenNow(until_date) === 'red'}
                                        /> : '-'
                              },
                              {
                                accessor: 'actions',
                                render: (p: any) => <ActionIcon
                                    color={'red'}
                                    onClick={() => openModal(p)}
                                    disabled={deleteInProgress === p.product_id}
                                    loading={deleteInProgress === p.product_id}>
                                  <FontAwesomeIcon icon={faTrash}/>
                                </ActionIcon>
                              }
                            ]}
                          records={productsData}
                          emptyState={
                            <Stack align="center" gap="xs">
                              <Text c="dimmed" size="sm">
                                No data found...
                              </Text>
                              <Button
                                  loading={loadingProductsData}
                                  disabled={loadingProductsData}
                                  onClick={fetchProductsData}
                              >
                                <FontAwesomeIcon icon={faRefresh}/>
                              </Button>
                            </Stack>
                          }
                      />
                  </Paper>
                  <Paper withBorder shadow={'md'} flex={1} p={'sm'}>
                      <Title order={5}>User info</Title>
                      <Title order={5}>Payment history</Title>
                  </Paper>
              </Flex>
          </Tabs.Panel>
      }

      {authState.user.permissions.includes('manage_courses') &&
          <Tabs.Panel value="courses" mt={'md'}>
              <DataTable
                  minHeight={400}
                  fetching={loadingCourseData}
                  columns={
                    [
                      { accessor: 'name' },
                      { accessor: 'current_time', title: 'Chapter time' },
                      { accessor: 'current_section', title: 'Chapter' },
                      {
                        accessor: 'state',
                        title: 'Completed - Passed - Review',
                        render: (r: any) => <Group>
                          {r.completed_at ?
                              <FontAwesomeIcon icon={faCheck} color={'teal'}/> :
                              <FontAwesomeIcon icon={faXmark} color={'red'}/>}
                          {r.passed_at ?
                              <FontAwesomeIcon icon={faCheck} color={'teal'}/> :
                              <FontAwesomeIcon icon={faXmark} color={'red'}/>}
                          {r.has_review ?
                              <FontAwesomeIcon icon={faCheck} color={'teal'}/> :
                              <FontAwesomeIcon icon={faXmark} color={'red'}/>}
                        </Group>
                      }
                    ]}
                  records={courseData}
                  rowExpansion={{
                    content: ({ record }) => {
                      const counter = record.answer_1 ? Object.keys(record.answer_1).length : 1;
                      return (
                            <Table>
                              <Table.Thead>
                                <Table.Tr>
                                  <Table.Th>Attempt</Table.Th>
                                  <Table.Th>Question</Table.Th>
                                  <Table.Th>Given answer</Table.Th>
                                  <Table.Th>Is correct?</Table.Th>
                                </Table.Tr>
                              </Table.Thead>
                              <Table.Tbody>
                                {
                                  record.mappedQuestionsAnswers.map((q: any, i: number) => (
                                      <Table.Tr key={`tr-${i}`}>
                                        <Table.Td>{Math.floor(i / counter) + 1}</Table.Td>
                                        <Table.Td>{q.question}</Table.Td>
                                        <Table.Td>{q.given_answer}</Table.Td>
                                        <Table.Td>{parseInt(q.given_answer_numeric) === q.correct_answer ? 'Yes' : 'No'}</Table.Td>
                                      </Table.Tr>
                                  ))
                                }
                              </Table.Tbody>
                            </Table>
                      )
                    },
                  }}
              />
          </Tabs.Panel>
      }

      {
          authState.user.permissions.includes('manage_security') &&
          <Tabs.Panel value="security" mt={'md'}>
              SE UTENTE HA PERMESSO DI ASSEGNARE I PERMESSI
              <p>ruoli - permessi</p>

              SICUREZZA, se utente ha permesso di gestire sicurezza
              storico logins (email + devices)
              location logins
              ip logins etc
          </Tabs.Panel>
      }
    </Tabs>
  </>
}