export const professions = [
    {label: 'Medic* chirurgo', value: 1},
    {label: 'Odontoiatra', value: 2},
    {label: 'Farmacista', value: 3},
    {label: 'Veterinari*', value: 4},
    {label: 'Psicolog*', value: 5},
    {label: 'Biolog*', value: 6},
    {label: 'Chimic*', value: 7},
    {label: 'Fisic*', value: 8},
    {label: 'Assistente sanitario', value: 9},
    {label: 'Dietista', value: 10},
    {label: 'Educatore professionale', value: 11},
    {label: 'Fisioterapista', value: 12},
    {label: 'Igienista dentale', value: 13},
    {label: 'Infermier*', value: 14},
    {label: 'Infermier* pediatrico', value: 15},
    {label: 'Logopedista', value: 16},
    {label: 'Ortottita/assistente di oftalmologia', value: 17},
    {label: 'Ostetric*', value: 18},
    {label: 'Podolog*', value: 19},
    {label: 'Tecnic* audiometrista', value: 20},
    {label: 'Tecnic* audioprotesista', value: 21},
    {label: 'Tecnic* della fisiopatologia cardiocircolatoria e perfusione cardiovascolare', value: 22},
    {label: 'Tecnic* della prevenzione nell\'ambiente e nei luoghi di lavoro', value: 23},
    {label: 'Tecnic* della riabilitazione psichiatrica', value: 24},
    {label: 'Tecnic* di neurofisiopatologia', value: 25},
    {label: 'Tecnic* ortopedic*', value: 26},
    {label: 'Tecnic* sanitari* di radiologia medica', value: 27},
    {label: 'Tecnic* sanitari* di laboratorio biomedico', value: 28},
    {label: 'Terapista della neuro e psicomotricità dell\'età evolutiva', value: 29},
    {label: 'Terapista occupazionale', value: 30}
]