import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
} from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ConnectDeviceModal(
    {
      opened, close, callback, device, devices
    }: any) {

  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState<boolean>(false);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {},
  });

  const submit = (values: any) => {
    setInProgress(true);
    fetch(`${import.meta.env.VITE_API_URL}/services/userDevices/`+device.id, {
      method: "PATCH",
      body: JSON.stringify(values),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then(callback)
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false);
        });
  }

  return (
      <Modal opened={opened} onClose={close} title="Info"
             size={'lg'}>

        <form onSubmit={form.onSubmit((values) => submit(values))}>
          <Box pos="relative">
            <LoadingOverlay visible={inProgress} zIndex={1000}
                            overlayProps={{ radius: "sm", blur: 2 }}/>
            <Stack>
              <Select
                  data={devices.filter((d: any) => d.type === 'vr-headset').map((d: any) => ({
                    label: d.name + ' ' + d.code,
                    value: d.id.toString(),
                  }))}
                  {...form.getInputProps('connected_to')}
                  label={t("connect_to", "Connetti con")}
                  required
              />
            </Stack>
          </Box>
          <Flex mt={'md'} justify={'flex-end'}>
            <Button type={'submit'} disabled={inProgress}
                    loading={inProgress}>{t('create', 'Crea')}</Button>
          </Flex>

        </form>
      </Modal>

  )
}