import {
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  TextInput,
  Title
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useTranslation } from "react-i18next";
import { useDisclosure, useFetch } from "@mantine/hooks";
import { DataTable } from "mantine-datatable";
import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";

export function Accounts (){
  const { t } = useTranslation();
  const [opened, {open, close}] = useDisclosure();
  const { data, loading, refetch } = useFetch<{ data: any[] }>(
      `${import.meta.env.VITE_API_URL}/services/accounts`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : t('invalid_email', 'Email non valida')),
    },
  });



  useEffect(() => {
    if(!opened){
      form.reset();
    }
  }, [opened])

  const onSubmit = (values: any) => {
    console.log('values', values)
    fetch(`${import.meta.env.VITE_API_URL}/services/accounts`, {
      body: JSON.stringify(values),
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          refetch();
          form.reset();
          close();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
  }

  return <>
    <Flex align={'center'} justify={'space-between'}>
      <Title>{t('accounts', 'Accounts')}</Title>
      <Group>
        <Button variant={'outline'} onClick={open}>
          <FontAwesomeIcon icon={faPlus} size="lg"/>
        </Button>
      </Group>
    </Flex>
    <Divider mb={'md'} mt={'md'}/>
    <DataTable
        fetching={loading}
        minHeight={400}
        withColumnBorders
        highlightOnHover
        records={data?.data}
        columns={[
          { accessor: 'id', title: 'ID' },
          { accessor: 'first_name', title: 'Name' },
          { accessor: 'last_name', title: 'Surname' },
          { accessor: 'email', title: 'Email' },
          { accessor: 'last_active', title: 'Last active' },
          { accessor: 'status', title: 'Status (online)' },
        ]}
    />
    <Modal opened={opened} onClose={close} title={t('create_ent_account', 'Aggiungi account')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput  {...form.getInputProps('email')} label={'Email'} />
        <Button mt='md' type={'submit'}>{t('create', 'Create')}</Button>
      </form>
    </Modal>
  </>
}