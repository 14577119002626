import {
  ActionIcon,
  Box,
  Chip,
  Flex,
  LoadingOverlay,
    Text,
  Select,
  Stack
} from "@mantine/core";
import { useFetch } from "@mantine/hooks";
import { useFormContext } from "../FormContext.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useTranslation } from "react-i18next";


export default function ExposureStep() {
  const form = useFormContext();
  const { t } = useTranslation();

  const { data, loading, refetch } = useFetch<{ data: any }>(
      `${import.meta.env.VITE_API_URL}/services/userDevices`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  return (
      <Box mt={'md'} mb={'md'} style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading}/>
        <Stack>
          <Text c={'yellow'}>-- Work in progress --</Text>
          <Flex justify={'space-between'} gap={'md'} align={'flex-end'}>
            <Select
                disabled
                size={'sm'}
                flex={1}
                clearable
                searchable
                label={t('select_food_list', 'Seleziona lista cibo')}
                {...form.getInputProps('foodListId')}
                data={data?.data?.foodLists}/>
            <ActionIcon size={'lg'} onClick={refetch}><FontAwesomeIcon
                icon={faRefresh}/></ActionIcon>
          </Flex>
          <Chip {...form.getInputProps('buffet', {type: 'checkbox'})}>
            {t('enable_buffet', 'Abilita buffet')}
          </Chip>

        </Stack>
      </Box>
  )
}