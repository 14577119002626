export const regioni = [
  'Abruzzo',
  'Basilicata',
  'Bolzano',
  'Calabria',
  'Campania',
  'Emilia Romagna',
  'Friuli Venezia Giulia',
  'Lazio',
  'Liguria',
  'Lombardia',
  'Marche',
  'Molise',
  'Piemonte',
  'Puglia',
  'Sardegna',
  'Sicilia',
  'Toscana',
  'Trento',
  'Umbria',
  'Valle d\'Aosta',
  'Veneto'
];