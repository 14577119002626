import {
  Accordion,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  List,
  LoadingOverlay,
  Stack,
  Stepper,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faCheck,
  faFilePdf,
  faXmark,
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useDisclosure, useFetch, useViewportSize } from "@mantine/hooks";
import { ICourse } from "../interfaces/ICourse.tsx";
import { useParams } from "react-router-dom";
import VideoComponent from "../components/courses/Video.tsx";
import QuizComponent from "../components/courses/Quiz.tsx";
import FeedbackComponent from "../components/courses/Feedback.tsx";
import { useAuth } from "../providers/AuthProvider.tsx";

export function ProCoursePage() {
  const { id } = useParams();
  const { authState } = useAuth();
  const theme = useMantineTheme();
  const { width } = useViewportSize();
  const { data, loading, refetch } = useFetch<{ data: ICourse }>(
      `${import.meta.env.VITE_API_URL}/services/userCourses/${id}`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );
  const course: any = data ? data.data : {};
  const [active, setActive] = useState(0);
  const [currentChapter, setCurrentChapter] = useState();
  const [modal1Opened, {
    open: openModal1,
    close: closeModal1
  }] = useDisclosure(false);
  const [modal2Opened, {
    open: openModal2,
    close: closeModal2
  }] = useDisclosure(false);


  const callback = () => {
    console.log('refetching')
    refetch();
  }

  useEffect(() => {
    if ( course ) {
      setActive(course.current_section)
    }
  }, [data])

  const calculateAttempts = (answers: any) => {
    const totalAttempts = 5; // Maximum number of attempts
    const usedAttempts = Object.keys(answers)
        .filter((key) => key.startsWith("answer_")) // Ensure only `answer_#` keys are counted
        .filter((key) => answers[key] !== null) // Count only non-null attempts
        .length;


    return `${usedAttempts}/${totalAttempts}`;
  };


  return <div style={{ position: 'relative' }}>
    <LoadingOverlay visible={loading}/>
    <Flex align={'center'} justify={'space-between'}
          direction={{ base: 'column', lg: 'row' }} gap={'md'}>
      <Title
          order={width < (parseFloat(theme.breakpoints.lg) * 16) ? 4 : 1}>{course?.name}</Title>
      {course.ecm && <Group>
        <Text>Nessuno sponsor</Text>
        <Group>
          <Image alt={'#provider# logo'} src={course?.provider_logo} h={30}
                 w={30}/>
          <Text>{course?.provider}</Text>
        </Group>
      </Group>}
    </Flex>

    <Divider mb={'md'} mt={'sm'}/>
    {course && <Flex direction={{ base: 'column', xl: 'row' }} gap="md">
        <Card withBorder
              flex={1}
              shadow="sm"
              padding="md"
              radius="md"
        >
            <Stepper active={active}
                     size={width < (parseFloat(theme.breakpoints.lg) * 16) ? 'sm' : 'md'}
                     onStepClick={(step) => {
                       if ( !course ) return;
                       console.log('step is', step)
                       if ( step === 0 || step <= course.current_section ) {
                         setActive(step)
                         setCurrentChapter(course.chapters[step])
                       }
                       if ( !course.ecm ) {
                         setActive(step)
                         setCurrentChapter(course.chapters[step])
                       }
                     }} orientation="vertical" mt={'md'}>
              {course?.chapters?.map((c: any, step: number) => <Stepper.Step
                  key={c.id}
                  style={{ pointerEvents: (step === 0 || step <= course?.current_section || !course.ecm) ? 'all' : 'none' }}
                  label={c.title} description={c.description}/>)}
            </Stepper>
        </Card>
      {course.ecm &&
          <Card withBorder
                flex={1}
                shadow="sm"
                padding="md"
                radius="md"
          >
              <Flex align={'center'} gap={'md'}
                    direction={{ base: 'column-reverse', lg: 'row' }}>
                  <Flex direction={'column'} flex={1}>
                      <Text>{course?.short_description}</Text>
                      <Text fw={700} mt={'md'}>Durata
                          corso: {course && course.duration ? (new Date(course.duration * 1000).toISOString().substring(11, 16)) : '-'}</Text>
                      <Text fw={700}>Totale ECM: {course?.ecm}</Text>
                  </Flex>
                  <Image alt={'#provider# logo'} src={course?.image} h={180}
                         w={180}
                         radius={'md'}/>

              </Flex>

              <Divider mt={'md'} mb={'md'}/>
              <List spacing={'lg'}>
                  <List.Item icon={course.completed_at ?
                      <FontAwesomeIcon icon={faCheck} color={'teal'}/> :
                      <FontAwesomeIcon icon={faXmark} color={'red'}/>}>
                    {course.completed_at ? 'Corso visualizzato' : 'Corso ancora da terminare'}
                  </List.Item>
                  <List.Item icon={course.review ?
                      <FontAwesomeIcon icon={faCheck} color={'teal'}/> :
                      <FontAwesomeIcon icon={faXmark} color={'red'}/>}>
                    {course.review ? 'Questionario inviato' :
                        <Tooltip
                            label={'Per inviare il questionario devi prima aver visualizzato il corso'}>
                          <Button
                              onClick={openModal2}
                              disabled={!course.completed_at}>
                            Invia questionario di gradimento
                          </Button>
                        </Tooltip>
                    }
                  </List.Item>
                  <List.Item icon={course.passed_at ?
                      <FontAwesomeIcon icon={faCheck} color={'teal'}/> :
                      <FontAwesomeIcon icon={faXmark} color={'red'}/>}>
                    {course.passed_at ? 'Corso superato' :
                        <Tooltip
                            label={'Per rispondere alle domande devi prima aver visualizzato il corso'}>
                          <Button
                              onClick={openModal1}
                              disabled={!course.completed_at}>
                            Rispondi alle domande
                            ({calculateAttempts(course)} tentativi)
                          </Button>
                        </Tooltip>
                    }
                  </List.Item>
              </List>

            {(course.completed_at && course.review && course.passed_at) ? <>
                  <Divider mt={'md'} mb={'md'}/>
                  <Stack>
                    <Button leftSection={<FontAwesomeIcon icon={faAward}/>}
                            onClick={() => window.open(`https://courses-v2.0.become-hub.com/${authState.user?.id}/certificates/${course.name}.pdf`, '_blank')}
                            size={width < (parseFloat(theme.breakpoints.lg) * 16) ? 'md' : 'lg'}
                            variant={'outline'}>Scarica certificato</Button>
                    {course.materials?.map((m: string, index: number) => (
                        <Button leftSection={<FontAwesomeIcon icon={faFilePdf}/>}
                                key={`btn-${index}`}
                                onClick={() => window.open(`https://courses-v2.0.become-hub.com/${course.url}/it/${m}`, '_blank')}
                                size={width < (parseFloat(theme.breakpoints.lg) * 16) ? 'md' : 'lg'}
                                variant={'outline'} color="orange">{m}</Button>
                    ))}
                  </Stack>
                </>
                :
                <Accordion mt={'lg'}>
                  {[course.answer_1, course.answer_2, course.answer_3, course.answer_4, course.answer_5].map((i: any, n: number) =>
                      i ? <Accordion.Item key={`k-${n}`} value={n.toString()}>
                        <Accordion.Control>Tentativo {n + 1}, risposte
                          sbagliate:</Accordion.Control>
                        <Accordion.Panel>
                          <Stack>
                            {Object.keys(i).map((key, nn: number) => <Flex
                                direction={'column'}
                                key={`f-${n}-${nn}`}><Text
                                fw={700}>{key}</Text><Text
                                c={'red'}>{i[key]}</Text></Flex>)}
                          </Stack>
                        </Accordion.Panel>
                      </Accordion.Item> : null
                  )}
                </Accordion>
            }
          </Card>
      }
    </Flex>}

    {currentChapter ?
        <VideoComponent
            c={course}
            currentChapter={currentChapter}
            setCurrentChapter={setCurrentChapter}
            callback={callback}/> : null}

    <QuizComponent
        callback={callback}
        c={course} opened={modal1Opened} onClose={closeModal1}/>

    <FeedbackComponent
        callback={callback}
        c={course} opened={modal2Opened} onClose={closeModal2}/>
  </div>

}