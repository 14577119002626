import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Loader,
  Modal,
  Text,
  Title,
  useMantineTheme
} from "@mantine/core";
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faLink,
  faLinkSlash,
  faPlus
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useDisclosure, useFetch } from "@mantine/hooks";
import CreateDeviceModal from "../components/CreateDevice.modal.tsx";
import ConnectDeviceModal from "../components/ConnectDevice.modal.tsx";
import { notifications } from "@mantine/notifications";
import { Trans, useTranslation } from "react-i18next";

export function Devices() {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const [infoOpened, {
    open: openInfo,
    close: closeInfo
  }] = useDisclosure(false);

  const [createOpened, {
    open: openCreate,
    close: closeCreate
  }] = useDisclosure(false);

  const [connectOpened, {
    open: openConnect,
    close: closeConnect
  }] = useDisclosure(false);

  const [lines, setLines] = useState<
      { x1: number; y1: number; x2: number; y2: number; id: number }[]
  >([]);
  const deviceRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [rows, setRows] = useState<any>([]);
  const [devices, setDevices] = useState<any>([]);
  const { data, loading } = useFetch<{ data: any[] }>(
      `${import.meta.env.VITE_API_URL}/services/userDevices`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );
  const [selectedDevice, setSelectedDevice] = useState<any>();
  const [unlinkProgress, setUnlinkProgress] = useState<number>();


  useEffect(() => {
    if ( devices.length === 0 ) return;
    const res = devices.reduce((acc: any, device: any) => {
      if ( device.type === 'vr-headset' ) {
        acc.push({ 'vr-headset': device, smartwatches: [] });
      } else if ( device.type === 'smartwatch' && device.connected_to ) {
        const row = acc.find((row: any) => row['vr-headset']?.id === device.connected_to);
        row?.smartwatches.push(device);
      } else if ( device.type === 'smartwatch' ) {
        acc.push({ 'vr-headset': null, smartwatches: [device] });
      }
      return acc;
    }, [] as {
      'vr-headset': typeof devices[0] | null;
      smartwatches: typeof devices[]
    }[])
    setRows(res)


    const calculateLines = () => {
      const newLines = devices
          .filter((device: any) => device.connected_to) // Only devices with connections
          .map((device: any) => {
            const vrHeadset = deviceRefs.current[device.connected_to!];
            const smartwatch = deviceRefs.current[device.id];
            if ( !vrHeadset || !smartwatch ) return null;

            const oculusRect = vrHeadset.getBoundingClientRect();
            const smartwatchRect = smartwatch.getBoundingClientRect();

            const svgOffset = document
                .querySelector('#svg-container')
                ?.getBoundingClientRect();

            if ( !svgOffset ) return null;

            return {
              x1: oculusRect.left - svgOffset.left + oculusRect.width / 2,
              y1: oculusRect.top - svgOffset.top + oculusRect.height / 2,
              x2: smartwatchRect.left - svgOffset.left + smartwatchRect.width / 2,
              y2: smartwatchRect.top - svgOffset.top + smartwatchRect.height / 2,
              id: device.id,
            };
          })
          .filter(Boolean) as {
        x1: number;
        y1: number;
        x2: number;
        y2: number;
        id: number
      }[];

      setLines(newLines);
    };
    setTimeout(() => {
      calculateLines();
    }, 600)

    window.addEventListener('resize', calculateLines);
    return () => window.removeEventListener('resize', calculateLines);
  }, [devices]);


  useEffect(() => {
    if ( data?.data ) {
      if ( Array.isArray(data.data) ) {
        setDevices(data.data)
      }
    }
  }, [data]);


  const callbackCreate = (json: any) => {
    console.log('callback is', json.data);
    setDevices([json.data, ...devices]);
    closeCreate();
  }


  const callbackConnect = (json: any) => {
    console.log('callback is', json.data);
    setDevices((prevDevices: any[]) => {
      // Check if the device already exists
      const existingIndex = prevDevices.findIndex((d: any) => d.id === json.data.id);

      if ( existingIndex > -1 ) {
        // Replace the existing device
        return prevDevices.map((d: any, index: number) =>
            index === existingIndex ? json.data : d
        );
      } else {
        // Add the new device to the list (e.g., at the end)
        return [...prevDevices, json.data];
      }
    });
    closeConnect();
    setSelectedDevice(undefined);
  }

  const disconnect = (userDeviceId: number) => {
    setUnlinkProgress(userDeviceId);
    fetch(`${import.meta.env.VITE_API_URL}/services/userDevices/` + userDeviceId, {
      method: "PATCH",
      body: JSON.stringify({
        connected_to: null,
      }),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then(callbackConnect)
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setUnlinkProgress(undefined);
        });
  }


  return <>
    <Flex align={'center'} justify={'space-between'}>
      <Title>{t('all_devices', 'Gestione dispositivi')}</Title>
      <Group>
        <Button variant={'outline'} color={'dark'} onClick={openInfo}>
          <FontAwesomeIcon icon={faInfoCircle} size="lg"/>
        </Button>
        <Button variant={'outline'} onClick={openCreate} disabled>
          <FontAwesomeIcon icon={faPlus} size="lg"/>
        </Button>
      </Group>
    </Flex>
    <Divider mb={'md'} mt={'md'}/>
    <Modal opened={infoOpened} onClose={closeInfo} title="Informazioni"
           size={'lg'}>
      <Trans i18nKey="all_devices_info">
        <Text>In questa pagina puoi gestire le <b>connessioni</b> tra i vari
          dispositivi. Per
          esempio se hai uno smartwatch e un dispositivo Oculus potrai fare in
          modo
          che i dati in uscita dallo smartwatch (come il battito cardiaco) siano
          ricevuti da un oculus.</Text>
        <br/>
        <Text>
          Questo è stato fatto perché è possibile avere più dispositivi di
          realtà
          aumentata
          e più sensori: il sistema permette di modificare a piacimento le varie
          connessioni.
        </Text>
        <br/>
        <Text>Per collegare un sensore al dispositivo VR clicca
          sull'icona <FontAwesomeIcon icon={faLink}/> posizionata a destra di un
          sensore. Per
          scollegarlo e poterlo connettere ad un altro dispositivo VR clicca
          sull'icona <FontAwesomeIcon icon={faLinkSlash}/>.</Text>
      </Trans>
    </Modal>


    {loading && <Flex justify={'center'} mt={'lg'}><Loader color="cyan"
                                                           size="lg"/></Flex>}
    <CreateDeviceModal opened={createOpened}
                       close={closeCreate}
                       callback={callbackCreate}/>

    <ConnectDeviceModal device={selectedDevice}
                        opened={connectOpened}
                        devices={devices}
                        close={closeConnect} callback={callbackConnect}/>

    <div id="svg-container" style={{ position: 'relative' }}>
      {/* SVG for lines */}
      <svg
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
      >
        {lines.map((line) => (
            <line
                key={line.id}
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
                stroke="url(#gradient)"
                strokeWidth="2"
            />
        ))}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={theme.colors.dark[2]} stopOpacity="1">
              <animate
                  attributeName="stop-color"
                  values={`${theme.colors.dark[8]}; ${theme.colors.blue[2]}; ${theme.colors.dark[8]}`}
                  dur="1.5s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="stop-opacity"
                  values="1; 0.5; 1"
                  dur="1.5s"
                  repeatCount="indefinite"
              />
            </stop>
            <stop offset="50%" stopColor={theme.colors.blue[4]}
                  stopOpacity="0.75">
              <animate
                  attributeName="stop-color"
                  values={`${theme.colors.blue[4]}; ${theme.colors.dark[8]}; ${theme.colors.blue[4]}`}
                  dur="1.5s"
                  repeatCount="indefinite"
              />
            </stop>
            <stop offset="100%" stopColor={theme.colors.blue[2]}
                  stopOpacity="0.75">
              <animate
                  attributeName="stop-color"
                  values={`${theme.colors.blue[4]}; ${theme.colors.dark[8]}; ${theme.colors.blue[4]}`}
                  dur="1.5s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="stop-opacity"
                  values="1; 0.5; 1"
                  dur="1.5s"
                  repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
          <filter id="glow">
            <feGaussianBlur stdDeviation="4" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
      </svg>

      {/* Device grid */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {rows.map((row: any, index: number) => (
            <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  alignItems: 'flex-start',
                }}
            >
              {/* Oculus */}
              <Box
                  ref={(el) => {
                    if ( row['vr-headset'] ) deviceRefs.current[row['vr-headset'].id] = el;
                  }}
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
              >
                {row['vr-headset'] ? (
                    <Card withBorder
                          style={{ width: 120, textAlign: 'center' }}>
                      <Image src="/meta-quest-3.webp" w={85}/>
                      <Text fs="italic">{row['vr-headset'].name}</Text>
                      <Text>{row['vr-headset'].code}</Text>
                    </Card>
                ) : (
                    <div style={{ width: 85, height: 85 }}/>
                )}
              </Box>

              {/* Smartwatches */}
              <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
              >
                {row.smartwatches.map((watch: any) => (
                    <Card
                        key={watch.id}
                        ref={(el) => {
                          deviceRefs.current[watch.id] = el;
                        }}
                        withBorder
                        style={{
                          width: 160,
                          textAlign: 'center',
                          position: 'relative'
                        }}
                    >
                      <Flex align={'center'} gap={'sm'}>
                        <Image src="/galaxy.avif" w={85}/>
                        {watch.connected_to ?
                            <ActionIcon
                                loading={unlinkProgress === watch.id}
                                disabled={unlinkProgress === watch.id}
                                onClick={() => {
                                  disconnect(watch.id);
                                }}
                                variant={'light'}>
                              <FontAwesomeIcon icon={faLinkSlash}/>
                            </ActionIcon> :
                            <ActionIcon variant={'light'}
                                        onClick={() => {
                                          setSelectedDevice(watch);
                                          openConnect();
                                        }}>
                              <FontAwesomeIcon
                                  icon={faLink}/>
                            </ActionIcon>
                        }
                      </Flex>
                      <Text>{watch.name}</Text>
                      <Text>{watch.code}</Text>
                    </Card>
                ))}
              </Box>
            </div>
        ))}
      </div>
    </div>
  </>
}