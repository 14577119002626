import GenericListComponent from "./GenericListComponent.tsx";


export default function AdminUsers() {

  const onRowClick = (record: any) => {
    window.open(`/admin/users/${record.id}`, '_blank')
  }


  return (
      <GenericListComponent
          name={'user'}
          onRowClick={onRowClick}
          columns={[
            { accessor: 'id', title: 'ID' },
            { accessor: 'first_name'},
            { accessor: 'last_name'},
            { accessor: 'email', title: 'Email' },
            { accessor: 'type', title: 'Type' },
            { accessor: 'belongs_to_enterprise', title: 'Enterprise user' },
          ]}
      />

  );
}