import {
  AppShell,
  Button,
  ButtonGroup,
  Flex,
  Loader,
  Title
} from '@mantine/core';
import { Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../providers/AuthProvider.tsx";


const Guest = () => {
  const { i18n } = useTranslation();
  const { authState } = useAuth();

  const changeLanguage = (lng: any) => {
    console.log('changing something maybe idk, ', lng)
    i18n.changeLanguage(lng).then(() => {
      console.log(`Language changed to: ${i18n.language}`);
    }).catch((error) => {
      console.error('Error changing language:', error);
    });
  };

  if (authState.loading) {
    return <Flex style={{minHeight: '100vh'}} justify={'center'} align={'center'}><Loader/></Flex>
  }


  if (authState.loggedIn) {
    return <Navigate to="/" replace />;
  }


  return (
      <AppShell
          header={{ height: 60 }}
          padding="md"
      >
        <AppShell.Header p={'sm'}>
          <Flex align={'center'} justify={'space-between'} style={{ height: 40 }}>
            <Title order={4}>BECOME.</Title>
            <ButtonGroup>
              <Button variant={'subtle'} onClick={() => changeLanguage('en')} disabled={i18n.language === 'en'}>English</Button>
              <Button variant={'subtle'} onClick={() => changeLanguage('it')} disabled={i18n.language === 'it'}>Italiano</Button>
              <Button variant={'subtle'} onClick={() => changeLanguage('pt')} disabled={i18n.language === 'pt'}>Português</Button>
            </ButtonGroup>
          </Flex>
        </AppShell.Header>

        <AppShell.Main>
          <Outlet/>
        </AppShell.Main>
      </AppShell>
  );
}

export default Guest;