import { LineChart } from '@mantine/charts';
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useFetch } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ConfigurationCompleted() {
  const { t } = useTranslation();
  const { id } = useParams()
  const navigate = useNavigate();

  const { data: config, loading } = useFetch<{ data: any }>(
      `${import.meta.env.VITE_API_URL}/services/configurations/${id}`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );
  const c = config?.data;
  const settings = c ? JSON.parse(c.config) : {};

  const myReferenceLines = c?.results ? JSON.parse(c.results).map((evt: any) => ({
    x: evt.date, // draws a vertical line at the date
    color: 'red.6',
    label: `State -> ${evt.state}`,
  })) : [];


  return (
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading}/>
        <Breadcrumbs>
          <Button onClick={() => navigate('/activeSessions')}
                  size={'compact-md'}
                  c={'blue'}
                  variant={'subtle'}>{t('all_sessions', 'Tutte le sessioni')}</Button>
          <Text>{t('session_number', 'Sessione numero')} {c?.id}</Text>
        </Breadcrumbs>
        <Divider mt={'md'} mb={'lg'}/>
        <Card shadow="sm" padding="sm" radius="md" withBorder mb={'md'}>
          <Flex justify={'space-between'} align={'center'}>
            <Group>
              <Text fw={500}>{t('vr_name', 'Nome visore')}:</Text>
              <Text fs="italic">{c?.deviceName}</Text>
              <Text fw={500}>{t('vr_code', 'Codice visore')}:</Text>
              <Text>{c?.deviceCode}</Text>

              <Text fw={500}>{t('sensor', 'Sensore')}:</Text>
              <Text>{c?.connectedTo}</Text>
            </Group>
          </Flex>
        </Card>
        <Flex direction={{ base: 'column', xl: 'row' }} gap={'md'}>
          <Card shadow="sm"
                padding="lg"
                radius="md"
                withBorder
                flex={c?.connectedTo === null ? 1 : 0}
                style={{ minWidth: 300 }}>
            <Stack flex={1}>
              {c?.scenario && <>
                  <Title order={5}>Scenario:</Title>
                  <Text>{c?.scenario}</Text>
              </>
              }

              <Title order={5}>{t('user', 'Utente')}:</Title>
              <Text>{c?.patientCode} [{c?.patient_id}]</Text>

              <Divider/>
              <Title order={5}>Duration: <span
                  style={{ fontWeight: 'normal' }}>{settings?.duration}</span></Title>

              <Title order={5}>Variation: <span
                  style={{ fontWeight: 'normal' }}>{settings?.variation}</span></Title>

            </Stack>
          </Card>


          {c?.hrData &&
              <Card shadow="sm" padding="lg" radius="md" withBorder flex={1}>
                  <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500}>{t('chart', 'Grafico misurazioni')}</Text>
                  </Group>
                  <Card.Section style={{ paddingRight: 24 }}>
                      <LineChart
                          mt={'md'}
                          h={300}
                          withDots={false}
                          data={JSON.parse(c.hrData)}
                          dataKey="date"
                          type="gradient"
                          strokeWidth={5}
                          curveType="bump"
                          tooltipAnimationDuration={200}
                          gradientStops={[
                            { offset: 0, color: 'red' },     // ~50 bpm
                            { offset: 57, color: 'orange' },  // ~90 bpm
                            { offset: 85, color: 'green' },     // ~110 bpm
                            { offset: 100, color: 'blue' } // ~120 bpm
                          ]}
                          series={[
                            { name: 'HR', color: 'indigo.6' },
                          ]}
                          referenceLines={[
                            {
                              y: settings?.baseline, // The value of the baseline (your calculated baseline)
                              color: 'yellow', // Yellow line
                              strokeWidth: 2, // Line thickness
                              label: 'Baseline', // Optional label
                            },
                            ...myReferenceLines
                          ]}
                      />
                  </Card.Section>


              </Card>
          }
        </Flex>


      </div>
  )

}