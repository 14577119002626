// ablyTokenManager.ts
const ABLY_TOKEN_STORAGE_KEY = "ablyTokenData";

export interface AblyTokenData {
  token: string;
  keyName: string;
  issued: number;
  expires: number; // timestamp
  capability: string;
  clientId: string;
}

/**
 * Returns a valid Ably token from localStorage if it hasn’t expired.
 * Otherwise, fetches a fresh token from your server, and caches it.
 */
export async function getAblyToken(fetchAblyToken: () => Promise<AblyTokenData>) {
  // 1. Check localStorage first
  const stored = localStorage.getItem(ABLY_TOKEN_STORAGE_KEY);
  if (stored) {
    const tokenData: AblyTokenData = JSON.parse(stored);
    // 2. If the token is still valid, return it
    if (Date.now() < tokenData.expires) {
      return tokenData;
    }
  }

  // 3. Otherwise, fetch a new one from your backend
  const newTokenData = await fetchAblyToken();

  // 4. Store it in localStorage for future use
  localStorage.setItem(ABLY_TOKEN_STORAGE_KEY, JSON.stringify(newTokenData));

  return newTokenData;
}

/**
 * Optional helper to clear the token from localStorage
 * (e.g., when user logs out).
 */
export function clearAblyToken() {
  localStorage.removeItem(ABLY_TOKEN_STORAGE_KEY);
}
