import {
  Button,
  Divider,
  Flex,
  List,
  LoadingOverlay,
  Modal,
  Radio,
  Stack,
  Text
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

export default function QuizComponent({
                                         c, opened, onClose, callback
                                       }: any) {
  const [questions, setQuestions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {},
    validate: {
      // Dynamic validation for required questions
      ...questions.reduce((acc: any, q: any) => {
        acc[`question-${q.questionId}`] = (value: any) =>
            value ? null : "This question is required";
        return acc;
      }, {}),
    },
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/services/getQuiz/${c.id}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      setQuestions(result.data);
    } catch ( error ) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // function generate quiz.
  useEffect(() => {
    if ( opened ) {
      fetchData();
    }
    return () => {
      form.reset();
    }
  }, [opened])

  const submit = (values: any) => {
    console.log('submit values', values)
    setIsSubmitting(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/submitQuiz/${c.id}`, {
      method: "POST",
      body: JSON.stringify({
        answers: values
      }),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json();
        })
        .then((r: any) => {
          notifications.show({
            color: r.data && r.data.passed ? 'teal' : 'red',
            withBorder: true,
            radius: 'lg',
            autoClose: false,
            message: r.message,
          })
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
              setIsSubmitting(false)
              callback();
              onClose();
            }
        )
  }

  const date = new Date();

  return <Modal opened={opened} onClose={onClose} title={'Test di fine corso'}
                fullScreen>

    {loading ? <>Loading... </> : <>
      <Text>{c.name}</Text>
      <Text>Data: {`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`}</Text>
      <Divider mt={'md'} mb={'md'}/>
      <form onSubmit={form.onSubmit((values) => submit(values))}
            style={{ position: 'relative' }}>
        <LoadingOverlay visible={isSubmitting}/>
        <List type={'ordered'} spacing={'lg'}>
          {questions?.map((q: any) => <List.Item key={`q-${q.questionId}`}>
            <Radio.Group
                {...form.getInputProps(`question-${q.questionId}`)}
                label={q.description}
                required
            >
              <Stack mt={'md'}>
                {q.answers.map((a: any) => (
                    <Radio key={`r-${q.questionId}-${a.index}`}
                           value={a.index.toString()}
                           label={a.text}/>))}
              </Stack>
            </Radio.Group>
          </List.Item>)}
        </List>
        <Flex justify={'flex-end'}  mt={'md'}>
          <Button type={'submit'} disabled={isSubmitting}
                  loading={isSubmitting}>Invia</Button>
        </Flex>
      </form>
    </>
    }
  </Modal>
}