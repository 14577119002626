import GenericListComponent from "./GenericListComponent.tsx";

export default function AdminCourseQuestions() {

  return (

      <GenericListComponent
          name={'courseQuestion'}
          columns={[
            { accessor: 'description', title: 'Description'},
            { accessor: 'answer', title: 'Answer 1'},
            { accessor: 'answer_2', title: 'Answer 2'},
            { accessor: 'answer_3', title: 'Answer 3'},
            { accessor: 'answer_4', title: 'Answer 4'},
            { accessor: 'answer_5', title: 'Answer 5'},
            { accessor: 'correct_answer', title: 'Correct Answer' },
            { accessor: 'course_name', title: 'Course' },
          ]}
      />
  );
}