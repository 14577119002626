import {
  ActionIcon,
  Button, Divider, Flex,
  LoadingOverlay,
  Modal,
  Stack,
} from '@mantine/core';
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
import { FormProvider } from "../../components/FormContext.tsx";
import { DynamicForm } from "./DynamicForm.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinimize } from "@fortawesome/pro-light-svg-icons/faMinimize";
import { faMaximize } from "@fortawesome/pro-light-svg-icons/faMaximize";


export function ResourceModal({
                                name,
                                opened,
                                close,
                                callback,
                                selectedRecord
                              }: any) {


  const [initializing, setInitializing] = useState(true);
  const [saving, setSaving] = useState<boolean>(false)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [columns, setColumns] = useState<any>({})

  const form = useForm({
    initialValues: {},
  });

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_URL}/services/admin/resourceForm/${name}`,
        {
          credentials: 'include',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => {
          console.log('columns', data.data)
          setColumns(data.data)
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => setInitializing(false));
  }, [])

  useEffect(() => {
    if ( selectedRecord ) {
      const formattedValues = Object.fromEntries(
          Object.entries(selectedRecord).map(([key, value]) => [
            key,
            typeof value === "object" && value !== null ? JSON.stringify(value, null, 2) : value,
          ])
      );

      form.setValues(formattedValues);
    }
  }, [selectedRecord])

  useEffect(() => {
    if ( !opened ) {
      form.reset()
    }
  }, [opened])

  const onSubmit = (values: any) => {
    setSaving(true)
    const url = selectedRecord ?
        `${import.meta.env.VITE_API_URL}/services/admin/resource/${name}/${selectedRecord.id}` :
        `${import.meta.env.VITE_API_URL}/services/admin/resource/${name}`;

    fetch(url,
        {
          body: JSON.stringify(values),
          credentials: 'include',
          method: selectedRecord ? 'PATCH' : 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => {
          callback(data.data[0], !!selectedRecord)
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => setSaving(false));


  }


  return (
      <>
        <Modal opened={opened} onClose={close}
               fullScreen={isFullScreen}
               closeOnEscape={false}
               style={{ position: "relative" }}
               closeOnClickOutside={false}
               title={<Flex align={'center'} gap={'md'}>
                 <ActionIcon
                     color={'gray'}
                     onClick={() => setIsFullScreen(!isFullScreen)} variant={'subtle'}>
                   <FontAwesomeIcon icon={isFullScreen ? faMinimize : faMaximize}/>
                 </ActionIcon>
                 {
                   selectedRecord ?
                       `Edit ${name} ${selectedRecord.name}` :
                       `Create new ${name}`}
               </Flex>}>
          <LoadingOverlay visible={initializing}/>

          <FormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
              <Stack>
                {Object.keys(columns).map((key: string, index: number) => (
                    <DynamicForm key={index} name={key} type={columns[key]}/>
                ))}
                <Divider mt={'md'} mb={'md'}/>
                <Button type={'submit'} disabled={saving}
                        loading={saving}>Save</Button>
              </Stack>

            </form>
          </FormProvider>
        </Modal>
      </>
  );
}