import { createTheme, MantineProvider } from "@mantine/core";
import { Route, Routes } from 'react-router-dom';
import { Homepage } from "./pages/Homepage.tsx";
import { ProCourses } from "./pages/ProCourses.tsx";
import { Devices } from "./pages/Devices.tsx";
import { ActiveSessions } from "./pages/ActiveSessions.tsx";
import { ProCoursePage } from "./pages/ProCoursePage.tsx";
import { Notifications } from "@mantine/notifications";

import '@mantine/core/styles.layer.css';
import '@mantine/charts/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.layer.css';
import Login from "./pages/Login.tsx";
import { AuthProvider } from "./providers/AuthProvider.tsx";
import Guest from "./layouts/Guest.tsx";
import Main from "./layouts/Main.tsx";
import { useSyncAcrossTabs } from "./store/useHeartRateStore.ts";
import ConfigurationDetails from "./pages/ConfigurationDetails.tsx";
import { ModalsProvider } from "@mantine/modals";
import Profile from "./pages/Profile.tsx";
import { FreeCourses } from "./pages/FreeCourses.tsx";
import { FreeApps } from "./pages/FreeApps.tsx";
import Register from "./pages/Register.tsx";
import AdminUsers from "./admin/Users.tsx";
import AdminUser from "./admin/User.tsx";
import { Accounts } from "./pages/Accounts.tsx";
import './sentry.ts';
import { CompletedSessions } from "./pages/CompletedSessions.tsx";
import ConfigurationCompleted from "./pages/ConfigurationCompleted.tsx";
import AdminAudios from "./admin/Audios.tsx";
import AdminVideos from "./admin/Videos.tsx";
import AdminProducts from "./admin/Products.tsx";
import AdminCourses from "./admin/Courses.tsx";
import AdminCourseChapters from "./admin/CourseChapters.tsx";
import AdminCourseQuestions from "./admin/CourseQuestions.tsx";


const theme = createTheme({
  /*components: {
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, ring: RingLoader },
        type: 'ring',
      },
    }),
  },*/
});

function App() {
  useSyncAcrossTabs();

  return (
      <MantineProvider theme={theme}>
        <ModalsProvider>
          <AuthProvider>
            <Notifications/>

            <Routes>

              {/* Guest Layout Routes */}
              <Route element={<Guest/>}>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                {/* Add register or other guest routes here */}
              </Route>

              <Route element={<Main/>}>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/freeCourses" element={<FreeCourses/>}/>
                <Route path="/proCourses" element={<ProCourses/>}/>
                <Route path="/proCourses/:id" element={<ProCoursePage/>}/>
                <Route path="/freeApps" element={<FreeApps/>}/>
                <Route path="/activeSessions" element={<ActiveSessions/>}/>
                <Route path="/completedSessions" element={<CompletedSessions/>}/>
                <Route path="/activeSessions/:id"
                       element={<ConfigurationDetails/>}/>
                <Route path="/completedSessions/:id"
                       element={<ConfigurationCompleted/>}/>
                <Route path="/devices" element={<Devices/>}/>

                <Route path="/accounts" element={<Accounts/>}/>

                {/* TODO CHECK ROLE */}
                <Route path={'/admin/courseChapter'} element={<AdminCourseChapters/>}/>
                <Route path={'/admin/courseQuestions'} element={<AdminCourseQuestions/>}/>
                <Route path={'/admin/courses'} element={<AdminCourses/>}/>
                <Route path={'/admin/products'} element={<AdminProducts/>}/>
                <Route path={'/admin/audios'} element={<AdminAudios/>}/>
                <Route path={'/admin/videos'} element={<AdminVideos/>}/>
                <Route path={'/admin/users'} element={<AdminUsers/>}/>
                <Route path={'/admin/users/:id'} element={<AdminUser/>}/>
              </Route>
            </Routes>

          </AuthProvider>
        </ModalsProvider>
      </MantineProvider>
  )
}

export default App
