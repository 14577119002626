import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  TextInput
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CreateDeviceModal(
    {
      opened, close, callback
    }: any) {

  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState<boolean>(false);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {},
  });

  useEffect(() => {
    return () => {
      form.reset();
    }
  }, [opened])


  const submit = (values: any) => {
    setInProgress(true);
    fetch(`${import.meta.env.VITE_API_URL}/services/userDevices`, {
      method: "POST",
      body: JSON.stringify(values),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then(callback)
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false);
        });
  }

  return (
      <Modal opened={opened} onClose={close} title="Info"
             size={'lg'}>

        <form onSubmit={form.onSubmit((values) => submit(values))}>
          <Box pos="relative">
            <LoadingOverlay visible={inProgress} zIndex={1000}
                            overlayProps={{ radius: "sm", blur: 2 }}/>
            <Stack>
              <TextInput
                  {...form.getInputProps('name')}
                  label={t('ccm_device', "Dai un nuome al tuo dispositivo per distinguerlo")}
                  placeholder={t('ccm_device_p', "Oculus ufficio 1")}
              />
              <Select
                  data={[
                    {
                      value: 'vr-headset',
                      label: 'VR Headset (Meta quest)',
                    },
                    {
                      value: 'smartwatch',
                      label: 'Smartwatch (Fitbit, Galaxy)',
                    },
                  ]}
                  {...form.getInputProps('type')}
                  label={t('ccm_type', "Tipo di dispositivo")}
                  required
              />
            </Stack>
          </Box>
          <Flex mt={'md'} justify={'flex-end'}>
            <Button type={'submit'} disabled={inProgress}
                    loading={inProgress}>{t('create', 'Crea')}</Button>
          </Flex>

        </form>
      </Modal>

  )
}