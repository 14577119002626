import {
  Box,
  Button,
  Container,
  Paper, SegmentedControl,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from "@mantine/form";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
      termsOfService: false,
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : t('invalid_email', 'Email non valida')),
    },
  });

  const onSubmit = (values: any) => {
    setLoggingIn(true);
    fetch(`${import.meta.env.VITE_API_URL}/auth/magicLinkAsk`, {
      method: "POST",
      body: JSON.stringify({
        email: values.email,
        from: window.location.origin,
        params: window.location.search,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((r) => {
          notifications.show({
            color: 'teal',
            withBorder: true,
            radius: 'lg',
            title: t('email_sent', 'Email inviata'),
            autoClose: false,
            message: r.message,
          })
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setLoggingIn(false);
        });
  }
  console.log(t('login_title'));
  return (<>
        <Container size={420} my={40}>
          <Title ta="center">
            {t('login_title', 'Ben ritrovato/a in BECOME.')}
          </Title>
          <SegmentedControl
              mt={'md'}
              fullWidth
              onChange={() => navigate('/register')}
              data={[
                { label: t('login', 'Login'), value: 'login' },
                { label: t('create_account', 'Crea account'), value: 'register' }
              ]}
          />

          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <form
                onSubmit={form.onSubmit(onSubmit)}>
              <Box style={{ position: 'relative' }}>
                <TextInput label="Email"
                           disabled={loggingIn}
                           key={form.key('email')}
                           {...form.getInputProps('email')}
                           placeholder="m.rossi@libero.it" required/>
              </Box>
              <Button
                  loading={loggingIn}
                  disabled={loggingIn}
                  fullWidth mt="xl" type="submit">
                {t('login', 'Login')}
              </Button>
            </form>
          </Paper>
        </Container>
      </>
  )
}