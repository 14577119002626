import { LeftBar } from "../components/LeftBar.tsx";
import {
  ActionIcon,
  AppShell,
  Burger,
  Button,
  ButtonGroup,
  Flex,
  Group,
  Title,
  Tooltip,
  useMantineColorScheme
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ProtectedRoute from "./ProtectedRoute.tsx";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoonStars,
  faSunBright,
  faWifi,
  faWifiSlash
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import WelcomeModal from "../components/Welcome.modal.tsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


const Main = () => {
  const { colorScheme, setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const { i18n } = useTranslation();
  const [opened, { toggle, close }] = useDisclosure();
  const location = useLocation()
  const { connectionStatus } = useAuth();

  const changeLanguage = (lng: any) => {
    console.log('changing something maybe idk, ', lng)
    i18n.changeLanguage(lng).then(() => {
      console.log(`Language changed to: ${i18n.language}`);
    }).catch((error) => {
      console.error('Error changing language:', error);
    });
  };


  useEffect(() => {
    close();
  }, [location])

  return (
      <ProtectedRoute>
        <AppShell
            layout={'alt'}
            header={{ height: 60 }}
            navbar={{
              width: 80,
              breakpoint: 'sm',
              collapsed: { mobile: !opened },
            }}
            padding="md"
        >
          <AppShell.Header p={'sm'}>
            <Flex align={'center'} style={{ height: 40 }}
                  justify={'space-between'}>
              <Burger
                  opened={opened}
                  onClick={toggle}
                  hiddenFrom="sm"
                  size="sm"
              />
              <Title order={4}>BECOME.</Title>
              <Group visibleFrom={'sm'}>
                <ActionIcon
                    variant={'subtle'}
                    onClick={() => setColorScheme(colorScheme === 'light' ? 'dark' : 'light')}><FontAwesomeIcon
                    icon={colorScheme === 'light' ? faMoonStars : faSunBright}/></ActionIcon>
                <Tooltip label={connectionStatus}>
                  <FontAwesomeIcon
                      fade
                      icon={connectionStatus === 'connected' ? faWifi : faWifiSlash}
                      color={connectionStatus === 'connected' ? 'teal' : 'red'}/>
                </Tooltip>
                <ButtonGroup>
                  <Button variant={'subtle'}
                          onClick={() => changeLanguage('en')}
                          disabled={i18n.language === 'en'}>English</Button>
                  <Button variant={'subtle'}
                          onClick={() => changeLanguage('it')}
                          disabled={i18n.language === 'it'}>Italiano</Button>
                  <Button variant={'subtle'}
                          onClick={() => changeLanguage('pt')}
                          disabled={i18n.language === 'pt'}>Português</Button>
                </ButtonGroup>
              </Group>

            </Flex>
          </AppShell.Header>

          <AppShell.Navbar><LeftBar toggle={toggle}/></AppShell.Navbar>

          <AppShell.Main>
            <Outlet/>
          </AppShell.Main>
        </AppShell>

        <WelcomeModal/>
      </ProtectedRoute>
  );
}

export default Main;