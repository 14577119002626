export const maps: any = {
  'performance' : [
    { value: 'volcano', label: 'Vulcano' },
    { value: 'ocean', label: 'Oceano' },
    { value: 'stadium', label: 'Stadio' },
  ],
  'exposure': [
    { value: 'bedroom', label: 'Camera da letto' },
    { value: 'kitchen', label: 'Cucina' },
    { value: 'restaurant', label: 'Ristorante' },
  ]
}
