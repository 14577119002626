import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Ably, { InboundMessage, TokenParams } from "ably";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Loader } from "@mantine/core";

import { useHeartRateStore } from "../store/useHeartRateStore.ts";
import { IUser } from "../interfaces/IUser.tsx";
import { AblyTokenData, getAblyToken } from "./ablyTokenManager"; // <--- our new token manager

type ConnectionStatus = "connected" | "connecting" | "disconnected";

type AuthState = {
  loading: boolean;
  loggedIn: boolean;
  user: IUser | null; // e.g. { id, name, email }
};

type AuthContextType = {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  sendMessage: (event: string, data: any) => void;
  connectionStatus: ConnectionStatus;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addEvent, loadFromLocalStorage, syncDevices } = useHeartRateStore();

  // --- Auth State ---
  const [authState, setAuthState] = useState<AuthState>({
    loading: true,
    loggedIn: false,
    user: null,
  });
  const [loading, setLoading] = useState(true); // For initial page load

  // --- Ably State & Connection ---
  const [ably, setAbly] = useState<Ably.Realtime | null>(null);
  const [connectionStatus, setConnectionStatus] =
      useState<ConnectionStatus>("disconnected");

  // For presence tracking
  const deviceTimestamps = useRef(new Map<string, number>());
  const [tempDevices, setTempDevices] = useState<string[]>([]);

  // 1. On mount, fetch user info
  useEffect(() => {
    loadFromLocalStorage();

    const fetchUser = async () => {
      try {
        const response = await fetch(
            `${import.meta.env.VITE_API_URL}/services/me`,
            { credentials: "include" } // includes HttpOnly cookies
        );

        if ( response.status === 403 ) {
          // Not logged in
          setAuthState({ loggedIn: false, user: null, loading: false });
          if (
              location.pathname !== "/login" &&
              location.pathname !== "/register"
          ) {
            navigate("/login");
          }
          return null;
        }

        if ( response.ok ) {
          const userData = (await response.json()).data;
          setAuthState({ loggedIn: true, user: userData, loading: false });
        } else {
          setAuthState({ loggedIn: false, user: null, loading: false });
        }
      } catch ( err ) {
        console.error("Error fetching user info:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  // 2. fetchAblyToken (talks to your server if needed)
  const fetchAblyToken = async (): Promise<AblyTokenData> => {
    console.info("[AuthProvider] Actually fetching a new Ably token from server");
    const response = await fetch(
        `${import.meta.env.VITE_API_URL}/services/ably`,
        { method: "GET", credentials: "include" }
    );
    if ( !response.ok ) {
      throw new Error("Failed to fetch Ably token");
    }
    return response.json(); // e.g. { token, keyName, issued, expires, ... }
  };

  // 3. Actually initialize the Ably connection
  //    This is called in the effect below, once per login (or user change).
  async function initializeAbly(userId: string) {
    try {
      setConnectionStatus("connecting");

      // 3a. Grab a token from localStorage if valid, otherwise from the server
      const tokenData = await getAblyToken(fetchAblyToken);

      // 3b. Create the Realtime instance
      const ablyInstance = new Ably.Realtime({
        authCallback: (_params: TokenParams, callback) => {
          callback(null, tokenData);
        },
      });

      // 3c. Listen for connection events
      ablyInstance.connection.on("connected", () => {
        console.log("[Ably] connected");
        setConnectionStatus("connected");

        // Subscribe to inbound messages, presence, etc.
        const channel = ablyInstance.channels.get(`private:${userId}`);

        // Inbound messages
        channel.subscribe((message: InboundMessage) => {
          handleIncomingMessage(message);
        });
      });

      ablyInstance.connection.on("failed", (err) => {
        console.error("[Ably] connection failed:", err);
        setConnectionStatus("disconnected");
      });

      return ablyInstance;
    } catch ( err ) {
      console.error("[AuthProvider] Error initializing Ably:", err);
      setConnectionStatus("disconnected");
      return null;
    }
  }


  // 4. Our effect that depends on `[loggedIn, user]` to initialize or close Ably
  useEffect(() => {
    let ablyInstance: Ably.Realtime | null = null;

    if ( authState.loggedIn && authState.user ) {
      // If we have a user, set up Ably
      const setupAbly = async () => {
        const instance = await initializeAbly(authState.user!.id.toString());
        if ( instance ) {
          setAbly(instance);
          ablyInstance = instance;
        }
      };
      setupAbly();

      // Cleanup: if user changes or logs out, or component unmounts
      return () => {
        if ( ablyInstance ) {
          // Clear any presence interval
          /*
          const presenceTimer = (ablyInstance as any).closePresenceInterval;
          if (presenceTimer) {
            clearInterval(presenceTimer);
          }*/
          ablyInstance.close();
          setAbly(null);
          setConnectionStatus("disconnected");
        }
      };
    } else {
      // No user => ensure we close any old connection
      if ( ably ) {/*
         if ((ably as any).closePresenceInterval) {
           clearInterval((ably as any).closePresenceInterval);
         }*/
        ably.close();
        setAbly(null);
        setConnectionStatus("disconnected");
      }
    }
  }, [authState.loggedIn, authState.user]);

  // 5. Helper to handle inbound messages
  function handleIncomingMessage(message: InboundMessage) {
    // Example of how you might handle a heartRate message
    if ( message.data instanceof ArrayBuffer  ) {
      const decodedString = new TextDecoder("utf-8").decode(message.data);
      const parsedData = JSON.parse(decodedString);
      if ( parsedData.name === "heartRate" ) {
        const deviceId = parsedData.data.code.includes('-') ? parsedData.data.code.replace(`${authState.user!.id}-`, ''): parsedData.data.code;
        const now = Date.now();

        deviceTimestamps.current.set(deviceId, now);
        setTempDevices((prev) => {
          if ( !prev.includes(deviceId) ) {
            return [...prev, deviceId];
          }
          return prev;
        });
      }
      return;
    }

    // If not binary, parse as JSON
    const data = JSON.parse(message.data);
    if ( data?.heartRate > 0 ) {
      addEvent({
        id: message.id,
        deviceCode: data.code,
        value: data.heartRate,
        timestamp: message.timestamp,
      });
    }
    if(message.name && (message.name === 'heartRate' || message.name === "Connected")){
      const deviceId = message.clientId ?? 'no client id';
      const now = Date.now();

      deviceTimestamps.current.set(deviceId, now);
      setTempDevices((prev) => {
        if ( !prev.includes(deviceId) ) {
          return [...prev, deviceId];
        }
        return prev;
      });
    }
  }

  // 6. sendMessage helper
  const sendMessage = (event: string, data: any) => {
    if ( !ably || ably.connection.state !== "connected" ) {
      console.error("[Ably] Not connected, cannot send message");
      return;
    }
    if ( !authState.user ) {
      console.warn("[AuthProvider] No user object, skipping sendMessage");
      return;
    }
    const channel = ably.channels.get(`private:${authState.user.id}`);
    channel.publish({ name: event, data: JSON.stringify(data) });
  };

  // 7. Sync presence devices + tempDevices into your store
  useEffect(() => {
    const syncDevicesAndTemp = () => {
      const now = Date.now();
      const timeout = 60*1000; // 60 seconds

      // Filter out "expired" devices
      console.log('temp devices', tempDevices)
      const filtered = tempDevices.filter((deviceId) => {
        const lastSeen = deviceTimestamps.current.get(deviceId) || 0;
        const isOnline = now - lastSeen < timeout;
        if ( !isOnline ) {
          deviceTimestamps.current.delete(deviceId);
        }
        return isOnline;
      });

      console.log('filtered', filtered)

      if ( JSON.stringify(filtered) !== JSON.stringify(tempDevices) ) {
        setTempDevices(filtered);
      }
      console.log('sync devices ', [...filtered])
      syncDevices([...filtered]);
    };

    syncDevicesAndTemp();
    const interval = setInterval(syncDevicesAndTemp, 5000);

    return () => clearInterval(interval);
  }, [tempDevices]);

  // 8. If still loading user info, show a spinner
  if ( loading ) {
    return (
        <Flex
            style={{ minHeight: "100vh" }}
            align="center"
            justify="center"
        >
          <Loader size="xl"/>
        </Flex>
    );
  }
  // 9. Provide context
  return (
      <AuthContext.Provider
          value={{ authState, setAuthState, sendMessage, connectionStatus }}
      >
        {children}
      </AuthContext.Provider>
  );
};

// 10. Custom hook to use AuthContext
export const useAuth = () => {
  const ctx = useContext(AuthContext);
  if ( !ctx ) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return ctx;
};
