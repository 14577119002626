import { useHeartRateStore } from "../store/useHeartRateStore.ts";
import { useEffect, useState } from "react";
import { LineChart } from '@mantine/charts';
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  NumberInput,
  Progress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faWifiSlash
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useAuth } from "../providers/AuthProvider.tsx";
import { useFetch } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";

export default function ConfigurationDetails() {
  const { t } = useTranslation();
  const { id } = useParams()
  const { authState, sendMessage } = useAuth();
  const navigate = useNavigate();
  const {
    events,
    baseline: storedBaseline,
    connectedDevices,
    clearEventsByDevice,
    loadFromLocalStorage
  } = useHeartRateStore();
  const [baselineInProgress, setBaselineInProgress] = useState<boolean>(false)
  const [baselineDuration, setBaselineDuration] = useState<number>(60)
  const [baseline, setBaseline] = useState<number>(storedBaseline)
  const { data: config, loading, refetch } = useFetch<{ data: any }>(
      `${import.meta.env.VITE_API_URL}/services/configurations/${id}`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );
  const c = config?.data;

  const [data, setData] = useState<any>([]);
  const [initializing, setInitializing] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [timeToRegisterBaselineLeft, setTimeToRegisterBaselineLeft] = useState(0)
  const [currentState, setCurrentState] = useState('p0');
  const [stateChanges, setStateChanges] = useState<
      { date: string; state: string }[]
  >([]);

  const [duration, setDuration] = useState<number>(120);
  const [variation, setVariation] = useState<number>(5);
  const [storing, setStoring] = useState<boolean>(false)
  const [firstLoad, setFirstLoad] = useState<boolean>(false)

  const [results, setResults] = useState<any[]>([]) // TODO food Exposure

  useEffect(() => {
    // Load from local storage on mount
    loadFromLocalStorage();

  }, [loadFromLocalStorage]);

  useEffect(() => {
    //console.log('data is', data)
    checkHeartRateState();
  }, [data])

  useEffect(() => {
    if ( !c ) return;
    if ( c.config ) {
      if(!firstLoad){
        const conf = JSON.parse(c.config)
        if ( conf.duration && conf.duration > 0 ) {
          setDuration(conf.duration)
        }
        if ( conf.variation && conf.variation > 1 ) {
          setVariation(conf.variation)
        }
        setFirstLoad(true)
      }
    }

    // Determine the latest event to append
    const event = c?.status === 'in-progress' ? events
        .sort((a, b) => b.timestamp - a.timestamp)
        .find(e => e.deviceCode === c.connectedTo) : null;


    if ( event ) {
      const timeString = new Date(event.timestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });

      if ( data.length === 0 ) {

        const deviceEvents = [...events]
            .filter(e => e.deviceCode === c.connectedTo)
            .sort((a, b) => a.timestamp - b.timestamp); // oldest to newest

        const formattedDeviceEvents = deviceEvents.map(e => ({
          HR: e.value,
          date: new Date(e.timestamp).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })
        }));

        setData(formattedDeviceEvents);

      } else {

        // If we already have data, just append the latest event found.
        const lastDataEntry = data[data.length - 1];
        // Check if we already have this timestamp to avoid duplicates
        if ( !lastDataEntry || lastDataEntry.date !== timeString ) {
          setData((prevData: any) => [...prevData, {
            HR: event.value,
            date: timeString,
          }]);
        }

      }

    }
  }, [events, c, firstLoad])

  useEffect(() => {
    if ( timeToRegisterBaselineLeft > 0 ) {
      setTimeout(() => {
        setTimeToRegisterBaselineLeft(timeToRegisterBaselineLeft - 1)
      }, 1000)
    }
  }, [timeToRegisterBaselineLeft])

  const startBaseline = () => {
    clear()
    setBaselineInProgress(true)
    setTimeToRegisterBaselineLeft(baselineDuration)

    setTimeout(() => {

      const baselineEvents = [...data];
      const heartRates = baselineEvents.map(b => b.HR);
      console.log('baseline event finished', baselineEvents)
      console.log('heartRates', heartRates)

      if ( heartRates.length === 0 ) {
        console.warn('No heart rate data available for baseline calculation');
        setBaseline(0); // Default to 0 or a fallback value
      } else {
        // Calculate the baseline as the average of heart rate values
        const total = heartRates.reduce((sum, value) => sum + value, 0);
        const average = total / heartRates.length;

        console.log(`Baseline calculated: ${average}`);
        setBaseline(average);


        notifications.show({
          color: 'teal',
          withBorder: true,
          radius: 'lg',
          title: 'START',
          autoClose: 1000,
          message: 'Session started on the device',
        })

        sendMessage('startExperience', {code: c?.deviceCode, command: 'start', map: c?.scenario})
        checkDuration(duration);
        setInProgress(true)
      }
      setBaselineInProgress(false)
    }, baselineDuration * 1000)
  }

  const checkDuration = (d: number) => {
    console.log('check duration', d)
    if ( d > 0 ) {
      setDuration(d - 1)
      setTimeout(() => {
        checkDuration(d-1);
      }, 1000)
    }
  }


  const startSession = () => {
    setInitializing(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/configurations/${id}`, {
      method: "PATCH",
      credentials: 'include',
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .then(() => {
          sendMessage('initSession', {sessionId: id, config: c});
          refetch();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInitializing(false);
        });
  }


  useEffect(() => {
    if ( duration === 0 ) {
      console.log('finish, storing')
      notifications.show({
        color: 'teal',
        withBorder: true,
        radius: 'lg',
        title: 'END',
        autoClose: 1000,
        message: 'Session finished, saving...',
      })
      store();
    }
  }, [duration])

  const store = () => {
    sendMessage('stopExperience', {code: c?.deviceCode, command: 'stop'})
    setStoring(true);

    const conf = JSON.parse(c.config)
    conf.baseline = baseline;

    console.log('conf stored', conf)
    fetch(`${import.meta.env.VITE_API_URL}/services/configurations/${id}`, {
      method: "POST",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hrData: data,
        results: c.type === 'performance'? stateChanges : results,
        config: conf
      })
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          clearEventsByDevice(c.connectedTo);
          navigate('/activeSessions')
          setStoring(false);
        });

    // TODO
    setResults([])
  }

  const stop = () => {
    // TODO send stop event
    setInProgress(false)
  }

  const clear = () => {
    setData([])
    setBaseline(0)
    clearEventsByDevice(c?.connectedTo)
  }


  const checkHeartRateState = () => {
    if ( !data.length || !baseline || !c ) return; // no data, do nothing
    const latestHr = data[data.length - 1].HR;
    const ratioFromBaseline = (latestHr - baseline) / baseline;

    let newState = 'p0'; // default is p0 (no significant change from baseline)

    if ( ratioFromBaseline > (variation * 2 / 100) ) {
      newState = 'p2';
    } else if ( ratioFromBaseline > variation / 100 ) {
      newState = 'p1';
    } else if ( ratioFromBaseline < -(variation * 2 / 100) ) {
      newState = 'p-2';
    } else if ( ratioFromBaseline < -variation / 100 ) {
      newState = 'p-1';
    }

    // If there's an actual state change, then update and notify
    if ( newState !== currentState ) {
      setCurrentState(newState);
      setStateChanges((prev) => [
        ...prev,
        { date: data[data.length - 1].date, state: newState },
      ]);
      sendMessage('changeState', {newState, deviceCode: c?.deviceCode, command: 'changeState'});
    }
  }

  console.log('connected devices', connectedDevices, 'device code', c?.deviceCode)
  const isVrConnected = connectedDevices.includes(c?.deviceCode) || connectedDevices.includes(authState?.user?.id + '-'  +c?.deviceCode) ;
  const isSensorConnected = connectedDevices.includes(authState?.user?.id + '-' + c?.connectedTo);
  const myReferenceLines = stateChanges.map((evt) => ({
    x: evt.date, // draws a vertical line at the date
    color: 'red.6',
    label: `State -> ${evt.state}`,
  }));


  return (
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading || storing}/>
        <Breadcrumbs>
          <Button onClick={() => navigate('/activeSessions')}
                  size={'compact-md'}
                  c={'blue'}
                  variant={'subtle'}>{t('all_sessions', 'Tutte le sessioni')}</Button>
          <Text>{t('session_number', 'Sessione numero')} {c?.id}</Text>
        </Breadcrumbs>
        <Divider mt={'md'} mb={'lg'}/>
        <Card shadow="sm" padding="sm" radius="md" withBorder mb={'md'}>
          <Flex justify={'space-between'} align={'center'}>
            <Group>
              <Image src="/meta-quest-3.webp" w={85}/>
              <Text fw={500}>{t('vr_name', 'Nome visore')}:</Text>
              <Text fs="italic">{c?.deviceName}</Text>
              <Text fw={500}>{t('vr_code', 'Codice visore')}:</Text>
              <Text>{c?.deviceCode}</Text>
            </Group>
            <Group mr={'md'}>
              {isVrConnected ? <>
                <Text>{t('connected', 'Connected')}</Text>
                <FontAwesomeIcon icon={faWifi} fade color={'teal'}/>
              </> : <>
                <Text>{t('not_connected', 'Not connected')}</Text>
                <FontAwesomeIcon icon={faWifiSlash} color={'red'}/>
              </>}
            </Group>
          </Flex>
        </Card>
        <Flex direction={{ base: 'column', xl: 'row' }} gap={'md'}>
          <Card shadow="sm"
                padding="lg"
                radius="md"
                withBorder
                flex={c?.connectedTo === null ? 1 : 0}
                style={{ minWidth: 300 }}>
            <Stack flex={1}>
              {c?.scenario && <>
                  <Title order={5}>Scenario:</Title>
                  <Text>{c?.scenario}</Text>
              </>
              }

              <Title order={5}>{t('user', 'Utente')}:</Title>
              <Text>{c?.patientCode} [{c?.patient_id}]</Text>

              <Divider/>
              {c?.connectedTo && <>
                  <Title order={5}>Duration: <span style={{fontWeight: 'normal'}}>{duration}</span></Title>

                  <Title order={5}>Variation: <span style={{fontWeight: 'normal'}}>{variation}</span></Title>

                  <Title order={5}>Current state: <span style={{fontWeight: 'normal'}}>{currentState}</span></Title>

                  <Title order={5}>Baseline: <span style={{fontWeight: 'normal'}}>{Math.round(baseline)}</span></Title>

                  <Title order={5}>Current heart rate: <span style={{fontWeight: 'normal'}}>{data[data.length - 1]?.HR}</span></Title>
              </>
              }
            </Stack>
            {c?.status !== 'in-progress' &&
                <Button color="blue"
                        fullWidth
                        disabled={initializing}
                        loading={initializing}
                        mt="md"
                        radius="md"
                        onClick={startSession}>
                  {t('start_session', 'Inizia sessione')}
                </Button>
            }
          </Card>


          {c?.connectedTo &&
              <Card shadow="sm" padding="lg" radius="md" withBorder flex={1}>
                  <Flex justify={'space-between'} align={'center'}>
                      <Text
                          fw={500}>{t('sensor', 'Sensore')}: {c?.connectedTo}</Text>
                      <Group>
                        {isSensorConnected ? <>
                          <Text>{t('connected', 'Connected')}</Text>
                          <FontAwesomeIcon icon={faWifi} fade color={'teal'}/>
                        </> : <>
                          <Text>{t('not_connected', 'Not connected')}</Text>
                          <FontAwesomeIcon icon={faWifiSlash} color={'red'}/>
                        </>}
                      </Group>
                  </Flex>
                  <Card.Section style={{ paddingRight: 24 }}>
                      <LineChart
                          mt={'md'}
                          h={300}
                          withDots={false}
                          data={data}
                          dataKey="date"
                          type="gradient"
                          strokeWidth={5}
                          curveType="bump"
                          tooltipAnimationDuration={200}
                          gradientStops={[
                            { offset: 0, color: 'red' },     // ~50 bpm
                            { offset: 57, color: 'orange' },  // ~90 bpm
                            { offset: 85, color: 'green' },     // ~110 bpm
                            { offset: 100, color: 'blue' } // ~120 bpm
                          ]}
                          series={[
                            { name: 'HR', color: 'indigo.6' },
                          ]}
                          referenceLines={[
                            {
                              y: baseline, // The value of the baseline (your calculated baseline)
                              color: 'yellow', // Yellow line
                              strokeWidth: 2, // Line thickness
                              label: 'Baseline', // Optional label
                            },
                            ...myReferenceLines
                          ]}
                      />
                  </Card.Section>

                  <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500}>{t('chart', 'Grafico misurazioni')}</Text>
                  </Group>

                {c?.status !== 'in-progress' ? <>
                      <Text>Per registrare la baseline è necessario iniziare
                        questa sessione</Text>
                    </> :
                    (inProgress ?
                            <Flex align={'center'} gap={'md'}>
                              <Text>Sessione in corso</Text>
                              <Button color={'red'} onClick={stop}>Termina
                                sessione sul
                                dispositivo</Button>
                            </Flex>
                            :
                            <Group align={'flex-end'}>
                              <NumberInput
                                  disabled={baselineInProgress}
                                  value={baselineDuration}
                                  onChange={(e) => setBaselineDuration(typeof e === 'string' ? parseInt(e) : e)}
                                  label={'Durata baseline'}
                              />
                              {timeToRegisterBaselineLeft > 0 ?

                                  <Progress radius="xl"
                                            ml={'sm'}
                                            size={35} w={150}
                                            value={(baselineDuration - timeToRegisterBaselineLeft) * 100 / baselineDuration}
                                            animated/>

                                  :
                                  <>
                                    <Button color="yellow" mt="md" radius="md"
                                            loading={baselineInProgress}
                                            disabled={!isSensorConnected}
                                            onClick={startBaseline}>
                                      {isSensorConnected ? t('register_baseline', 'Registra Baseline') : 'In attesa del sensore...'}
                                    </Button>
                                    <Button color="red" mt="md" radius="md"
                                            loading={baselineInProgress}
                                            disabled={baselineInProgress}
                                            onClick={clear}>
                                      {t('delete_events', 'Elimina tutti gli eventi passati')}
                                    </Button>
                                  </>
                              }

                            </Group>
                    )
                }
              </Card>
          }
        </Flex>


      </div>
  )

}