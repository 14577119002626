import { useDocumentTitle, useFavicon, useFetch, } from "@mantine/hooks";
import { ICourse } from "../interfaces/ICourse.tsx";
import { Divider, Grid, Loader, Title, Transition } from "@mantine/core";
import { useEffect, useState } from "react";
import CourseCard from "../components/courses/card.tsx";


export function ProCourses() {
  useDocumentTitle('BECOME | Tutti i corsi ECM');
  useFavicon('/favicon.ico');
  const [mountedCards, setMountedCards] = useState<number[]>([]);
  const { data, loading } = useFetch<{ data: ICourse[] }>(
      `${import.meta.env.VITE_API_URL}/services/userCourses`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  useEffect(() => {
    if ( data ) {
      data.data?.filter(i => i.ecm > 0).forEach((_, index) => {
        setTimeout(() => {
          setMountedCards((prev) => [...prev, index]);
        }, 50 * index); // Add a delay of 50ms per card
      });
    }
  }, [data]);

  return (
      <>
        <Title>Piattaforma E-Learning | ECM</Title>
        <Title order={4} c={'dimmed'}>Corsi riservati a professionisti italiani
          di ambito sanitario</Title>
        <Divider mt={'xs'}/>
        <Grid gutter={{ base: 5, xs: 'md', md: 'xl' }}
              mt={'md'}
              style={{ minHeight: 150 }}>
          {loading ? <Grid.Col span={12} ta={'center'}><Loader/></Grid.Col> :
              data?.data
                  .sort((a, b) => (a.unlocked ? 0 : 1) - (b.unlocked ? 0 : 1)).filter(i => i.ecm > 0).map((c: ICourse, index: number) => (
                  <Transition
                      key={c.id}
                      transition="fade-left"
                      duration={240}
                      timingFunction="ease"
                      onEnter={() => console.info('transition enter', index)}
                      mounted={mountedCards.includes(index)}
                  >
                    {(styles) => (
                        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                          <CourseCard c={c} style={styles}/>
                        </Grid.Col>
                    )}
                  </Transition>
              ))
          }
        </Grid>
      </>
  )
}