import GenericListComponent from "./GenericListComponent.tsx";


export default function AdminVideos() {


  return (
      <GenericListComponent
          name={'video'}
          columns={[
            { accessor: 'id', title: 'ID' },
            { accessor: 'name', title: 'Name' },
            { accessor: 'type', title: 'Type' },
            { accessor: 'category', title: 'category' },
            { accessor: 'url', title: 'Url' },
            { accessor: 'version', title: 'Version' },
            { accessor: 'duration', title: 'Duration' },
            { accessor: 'size', title: 'Size' },
            { accessor: 'product_name', title: 'Product' },
          ]}

      />
  );
}