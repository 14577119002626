import GenericListComponent from "./GenericListComponent.tsx";

export default function AdminAudios() {

  return (

      <GenericListComponent
          name={'audio'}
          columns={[
            { accessor: 'id', title: 'ID' },
            { accessor: 'name', title: 'Name' },
            { accessor: 'url', title: 'Url' },
            { accessor: 'lang', title: 'Lang' },
            { accessor: 'size', title: 'Size' },
            { accessor: 'video_name', title: 'Video' },
            { accessor: 'email', title: 'User' },
            { accessor: 'type', title: 'Type' },
          ]}
      />
  );
}