import {
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Text, TextInput,
  Title,
  Transition
} from "@mantine/core";
import Vimeo from '@u-wave/react-vimeo';
import VideoCard from "../components/VideoCard.tsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import classes from './FreeApps.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@awesome.me/kit-528b119969/icons/classic/regular";
import { faSearch } from "@awesome.me/kit-528b119969/icons/duotone/solid";


export function FreeApps() {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [active, setActive] = useState<string>();
  const [value, setValue] = useState<string[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<any>();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0)
  const [mountedCards, setMountedCards] = useState<number[]>([]);
  const { data, loading } = useFetch<{ data: any }>(
      `${import.meta.env.VITE_API_URL}/services/userVideos?category=2d`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  useEffect(() => {
    if ( data ) {
      console.log('data, ', data.data)
      data.data?.forEach((_: any, index: number) => {
        setTimeout(() => {
          setMountedCards((prev) => [...prev, index]);
        }, 50 * index); // Add a delay of 50ms per card
      });
    }
  }, [data]);

  const groupedVideos: any = useMemo(() => {
    if ( !data ) return [];

    return Object.entries(
        data.data.reduce((groups: Record<string, any[]>, video: any) => {
          const category = video.sub_category || 'Uncategorized'; // Fallback for missing category
          if ( !groups[category] ) {
            groups[category] = [];
          }
          groups[category].push(video);
          return groups;
        }, {})
    );
  }, [data]);

  const filteredVideos = useMemo(() => {
    if (value.length === 0 && !search) return groupedVideos;

    // Filter grouped videos
    return groupedVideos.map(([category, videos]: [string, any[]]) => {
      // Filter videos by search and category
      const filteredVideos = videos.filter((video: any) => {
        const matchesSearch = search
            ? video.name.toLowerCase().includes(search.toLowerCase())
            : true;
        const matchesCategory = value.length === 0 || value.includes(category);

        return matchesSearch && matchesCategory;
      });

      // Return the category and filtered videos
      return [category, filteredVideos];
    })
        .filter(([_, videos]: any) => videos.length > 0); // Remove empty categories
  }, [groupedVideos, value, search]);


  // scroll behaviour
  const rowRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const scrollLeft = (category: string) => {
    console.log('scroll left', category)
    if ( rowRefs.current[category] ) {
      rowRefs.current[category]?.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };
  const scrollRight = (category: string) => {
    console.log('scroll right', category)
    if ( rowRefs.current[category] ) {
      rowRefs.current[category]?.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };


  return <>
    <Flex align={'center'} justify={'space-between'} direction={{base: 'column', md: 'row'}} gap={'md'}>
      <Title flex={1}>{t('fa_free_apps', 'App 2D')}</Title>
      <TextInput value={search}
                 w={'100%'}
                 style={{maxWidth: 450}}
                 placeholder={'Cerca video: es: Il Giardino Segreto'}
                 rightSection={<FontAwesomeIcon icon={faSearch}/> }
                 onChange={(v) => setSearch(v.target.value)}/>
    </Flex>
    <Divider mb={'md'} mt={'md'}/>
    <div style={{ position: 'relative', minHeight: 150 }}>
      {loading ?
          <Loader/> : (
              // Render grouped videos by category
              <>
                <Checkbox.Group
                    visibleFrom={'md'}
                    className={classes.checkbox}
                    value={value}
                    onChange={setValue}
                >
                  {groupedVideos.map(([category]: any) => (
                      <Checkbox.Card radius="md"
                                     className={value.includes(category) ? classes.activeCard : classes.inactiveCard}
                                     value={category} key={category}>
                        <Group wrap="nowrap" align="flex-start">
                          <div>
                            <Text>{category}</Text>
                          </div>
                        </Group>
                      </Checkbox.Card>
                  ))}
                </Checkbox.Group>
                {filteredVideos.map(([category, videos]: any) => (
                    <>
                      <Flex align={'center'} ml={15} mt={'xl'} mb={'sm'} gap={'md'}>
                        <Title order={4}
                        >{category}</Title>
                        {active === category ?
                            <Button variant={'subtle'}
                                    onClick={() => setActive(undefined)}>Nascondi</Button> :
                            <Button variant={'subtle'}
                                    onClick={() => setActive(category)}>Vedi
                              tutti</Button>
                        }
                      </Flex>
                      <div style={{ position: 'relative', marginLeft: 15, }}>
                        {active === category ? null : <>
                          <ActionIcon variant={'white'}
                                      size={'xl'}
                                      color={'black'}
                                      className={classes.buttonLeft}
                                      onClick={() => scrollLeft(category)}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                          </ActionIcon>
                          <ActionIcon variant={'white'}
                                      color={'black'}
                                      size={'xl'}
                                      className={classes.buttonRight}
                                      onClick={() => scrollRight(category)}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                          </ActionIcon>
                        </>}
                        <Flex
                            ref={(el) => {
                              rowRefs.current[category] = el; // Assign ref directly
                            }}
                            gap={{ base: 5, xs: 'md', md: 'xl' }}
                            key={category}
                            wrap={active === category ? 'wrap' : 'nowrap'}
                            justify="start"
                            className={active === category ? '' : classes.scrollBar }
                            mt={'md'}>
                          {videos
                              .sort((a: any, b: any) => (a.has_product ? 0 : 1) - (b.has_product ? 0 : 1))
                              .map((video: any, index: number) => (
                                  <Transition
                                      key={video.id}
                                      transition="fade-left"
                                      duration={240}
                                      timingFunction="ease"
                                      onEnter={() => console.info('transition enter', index)}
                                      mounted={mountedCards.includes(index)}
                                  >
                                    {(styles) => (
                                        <VideoCard
                                            style={styles}
                                            video={video}
                                            setSelectedVideo={setSelectedVideo}
                                        />
                                    )}
                                  </Transition>
                              ))}
                          {category.toLowerCase() === 'wellbeing' &&
                              <VideoCard
                                  video={[572129372, 889641668, 699844408]}
                                  title={'Giardino Segreto - La Spiaggia al Tramonto - La Cascata nella Prateria'}
                                  image={'/loop.webp'}
                                  setSelectedVideo={setSelectedVideo}/>}
                        </Flex>
                      </div>
                    </>
                ))}
              </>
          )}


    </div>
    <Modal
        opened={selectedVideo !== undefined}
        onClose={() => setSelectedVideo(undefined)}
        title={selectedVideo?.title}
        centered
        size={"xl"}
        closeOnEscape
    >
      {
        selectedVideo ? (
                selectedVideo.s3_folder ? <Vimeo
                    video={selectedVideo.s3_folder}
                    autoplay={false}
                    showPortrait={false}
                    showByline={false}
                    showTitle={false}
                    responsive={true}
                    dnt={true}
                /> : <Vimeo
                    video={selectedVideo[selectedVideoIndex]}
                    autoplay={true}
                    onEnd={() => {
                      let i = selectedVideoIndex + 1;
                      if ( i >= selectedVideo.length ) {
                        i = 0;
                      }
                      setSelectedVideoIndex(i)
                    }}

                    showPortrait={false}
                    showByline={false}
                    showTitle={false}
                    responsive={true}
                    dnt={true}
                />
            ) :
            null
      }
      <Divider mt={12} mb={12}/>
      <div><Text>{selectedVideo?.short_description}</Text></div>
    </Modal>
  </>
}