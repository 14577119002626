import GenericListComponent from "./GenericListComponent.tsx";
import { Button, ButtonGroup, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlockQuestion,
  faBookmark,
  faWrench
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useRef } from "react";


export default function AdminCourses() {
  const listRef = useRef<any>(null); // Create ref for GenericListComponent

  const handleEdit = (record: any) => {
    if ( listRef.current ) {
      listRef.current.setSelectedRecord(record); // Call the function inside the child
    }
  };

  return (

      <GenericListComponent
          ref={listRef} // Attach ref to GenericListComponent
          name={'course'}
          onRowClick={false}
          columns={[
            { accessor: 'id', title: 'ID' },
            { accessor: 'name', title: 'Name' },
            { accessor: 'product_name', title: 'Product' },
            { accessor: 'url', title: 'Url' },
            { accessor: 'materials', title: 'Materials' },
            {
              accessor: '-', title: 'Actions', render: (i: any) => (
                  <ButtonGroup>
                    <Tooltip label={'Edit'}>
                      <Button color="gray"
                              onClick={() => handleEdit(i)}
                              variant={'outline'}
                              size={'compact-md'}>
                        <FontAwesomeIcon icon={faWrench}/>
                      </Button>
                    </Tooltip>
                    <Tooltip label={'Edit chapters'}>
                      <Button color="gray"
                              onClick={() => window.open(`/admin/courseChapter?course_id=${i.id}`, '_blank')}
                              variant={'outline'}
                              size={'compact-md'}>
                        <FontAwesomeIcon icon={faBookmark}/>
                      </Button>
                    </Tooltip>
                    <Tooltip label={'Edit Questions'}>
                      <Button color="gray" variant={'outline'}
                              onClick={() => window.open(`/admin/courseQuestions?course_id=${i.id}`, '_blank')}
                              size={'compact-md'}>
                        <FontAwesomeIcon icon={faBlockQuestion}/>
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
              )
            },
          ]}
      />
  );
}