import { Box, NumberInput, Stack } from "@mantine/core";
import { useFormContext } from "../FormContext.tsx";
import { useTranslation } from "react-i18next";

export default function PerformanceStep() {
  const { t } = useTranslation();
  const form = useFormContext();

  return (
      <Box mt={'md'} mb={'md'}>
        <Stack>
        <NumberInput
            label={t('pp_variation', 'Variazione in percentuale')}
            rightSection={'%'}
            {...form.getInputProps('variation')}/>
          <NumberInput
              label={t('pp_duration', 'Durata massima sessione (in secondi)')}
              rightSection={'s'}
              {...form.getInputProps('duration')}/>
        </Stack>
      </Box>
  )
}