import { Card, Image, Text } from "@mantine/core";

function VideoCard({ video, setSelectedVideo, image, title, style }: any) {
  const enabled = !video.id || video.has_product
  return (
      <Card withBorder
            key={`v-${video.id}`}
            style={{
              height: '100%',
              flex: '0 0 300px',
              boxSizing: 'border-box',
              cursor: enabled ? "pointer" : "not-allowed",
              filter: enabled ? 'none' : "grayscale(100)",
              ...style
            }}
            padding="lg"
            onClick={() => {
              if ( enabled ) {
                setSelectedVideo(video.url ? [video.url] : video)
              }
            }}>
        <Card.Section>
          <Image
              src={image ?? video.image}
              alt={'Video cover'}
              height={200}
          />
        </Card.Section>

        <Text fz="sm" fw={700} mt={'md'}>
          {title ?? video.name}
        </Text>
      </Card>
  );
}

export default VideoCard;