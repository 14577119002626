import { Link, useLocation, useNavigate } from 'react-router-dom';
import classes from './LeftBar.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComputerClassic,
  faGraduationCap,
  faHeadSideGoggles,
  faHome,
  faLaptopMobile,
  faMoonStars,
  faSignOutAlt, faStore,
  faSunBright,
  faUserCrown,
  faUserEdit,
  faUsersGear,
  faWifi,
  faWifiSlash,
  faVideoCamera, faHeadphones,
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useAuth } from "../providers/AuthProvider.tsx";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import {
  ActionIcon,
  Burger,
  Button,
  ButtonGroup,
  Flex,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";



export function LeftBar({ toggle }: any) {
  const { colorScheme, setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { authState, setAuthState, connectionStatus } = useAuth();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const changeLanguage = (lng: any) => {
    console.log('changing something maybe idk, ', lng)
    i18n.changeLanguage(lng).then(() => {
      console.log(`Language changed to: ${i18n.language}`);
    }).catch((error) => {
      console.error('Error changing language:', error);
    });
  };

  const data: any = [
    {
      link: '/', label: 'Homepage',
      accountType: ['enthusiast', 'student', 'professional', 'enterprise', 'enterprise_member'],
      icon: faHome
    },
    {
      link: '/freeCourses',
      disabled: i18n.language !== 'it',
      label: t('left_bar_free_courses', 'Corsi ad accesso libero'),
      accountType: ['enthusiast', 'student'],
      icon: faGraduationCap
    },
    {
      link: '/proCourses',
      disabled: i18n.language !== 'it',
      label: t('left_bar_pro_courses', 'Corsi per professionisti'),
      accountType: ['student', 'professional', 'enterprise_member'],
      icon: faGraduationCap
    },
    {
      link: '/devices',
      label: t('left_bar_devices', 'Gestione dispositivi'),
      accountType: ['enthusiast', 'student', 'professional', 'enterprise_member'],
      icon: faLaptopMobile
    },
    {
      link: '/freeApps',
      label: t('left_bar_free_apps', 'App web'),
      accountType: ['enthusiast', 'student', 'professional', 'enterprise_member'],
      icon: faComputerClassic
    },
    {
      link: '/activeSessions',
      label: t('left_bar_pro_apps', 'Augmented Apps'),
      accountType: ['student', 'professional', 'enterprise_member'],
      icon: faHeadSideGoggles
    },
    {
      link: '/profile',
      label: t('left_bar_profile', 'Profilo'),
      accountType: ['enthusiast', 'student', 'professional', 'enterprise', 'enterprise_member'],
      icon: faUserEdit
    },
    {
      link: '/accounts',
      label: t('left_bar_accounts', 'Accounts'),
      accountType: ['enterprise'],
      icon: faUsersGear
    },
    {
      link: '/admin/users',
      label: t('left_bar_admin_users', 'Admin Users'),
      roles: ['admin', 'moderator'],
      icon: faUserCrown,
      color: 'orange'
    },
    {
      link: '/admin/products',
      label: t('left_bar_admin_products', 'Admin Store'),
      roles: ['admin', 'moderator'],
      icon: faStore,
      color: 'orange'
    },
    {
      link: '/admin/videos',
      label: t('left_bar_admin_videos', 'Admin Videos'),
      roles: ['admin', 'moderator'],
      icon: faVideoCamera,
      color: 'orange'
    },
    {
      link: '/admin/audios',
      label: t('left_bar_admin_audios', 'Admin Audios'),
      roles: ['admin', 'moderator'],
      icon: faHeadphones,
      color: 'orange'
    },
    {
      link: '/admin/courses',
      label: t('left_bar_admin_courses', 'Admin Courses'),
      roles: ['admin', 'moderator'],
      icon: faGraduationCap,
      color: 'orange'
    },
  ];

  const links = data.filter((item: any) => {
    const accountTypeMatch = item.accountType ? item.accountType.includes(authState.user?.type ?? '') : true;
    const roleMatch = item.roles ? item.roles.some((role: string) => authState.user?.roles?.includes(role)) : true;

    return accountTypeMatch && roleMatch;
  })
      .map((item: any) => (
          <Link
              style={{
                opacity: item.disabled ? 0.5 : 1,
                pointerEvents: item.disabled ? 'none' : 'auto',
                textAlign: isMobile ? 'left' : 'center',
                marginBottom: 18
              }}
              data-active={location.pathname === item.link || undefined}
              className={classes.link}
              to={item.link}
              key={item.label}
          >
            {isMobile ? <>
                  <FontAwesomeIcon icon={item.icon} size={'xl'} fixedWidth
                                   color={item.color ?? 'default'}/>
                  <Text ml={'md'}>{item.label}</Text>
                </> :
                <Tooltip label={item.label} position={'right'}>
                  <FontAwesomeIcon icon={item.icon} size={'xl'}
                                   color={item.color ?? 'default'}
                                   style={{ margin: 'auto' }} fixedWidth/>
                </Tooltip>
            }
          </Link>
      ));

  const logout = async () => {
    try {
      const r = await fetch(`${import.meta.env.VITE_API_URL}/auth/logout`, {
        method: "POST",
        credentials: "include",
      });
      if ( r.ok ) {
        setAuthState({ loggedIn: false, user: null, loading: false });
        navigate("/login");
      } else {
        notifications.show({
          color: 'red',
          withBorder: true,
          radius: 'lg',
          title: 'An error occurred',
          autoClose: false,
          message: 'Unable to logout',
        })
      }
    } catch ( error ) {
      console.error("Error logging out:", error);
    }
  };

  return (
      <nav className={classes.navbar}>

        <Burger opened={true} onClick={toggle} hiddenFrom="sm" size="sm"/>

        <div className={classes.navbarMain}>
          {links}
          {isMobile && <a className={classes.link}
                          onClick={(e) => {
                            e.preventDefault();
                            logout()
                          }}>
              <FontAwesomeIcon icon={faSignOutAlt} size={'xl'}
                               fixedWidth/>
              <Text ml={'md'} hiddenFrom={'md'}>Logout</Text>
          </a>}
        </div>

        <div className={classes.footer}>
          <Flex hiddenFrom={'sm'} align={'center'} justify={'space-around'}
                mt={'md'}>
            <ActionIcon
                variant={'subtle'}
                onClick={() => setColorScheme(colorScheme === 'light' ? 'dark' : 'light')}><FontAwesomeIcon
                icon={colorScheme === 'light' ? faMoonStars : faSunBright}/></ActionIcon>
            <Tooltip label={connectionStatus}>
              <FontAwesomeIcon
                  fade
                  icon={connectionStatus === 'connected' ? faWifi : faWifiSlash}
                  color={connectionStatus === 'connected' ? 'teal' : 'red'}/>
            </Tooltip>
          </Flex>
          <ButtonGroup mt={'md'} mb={'md'} hiddenFrom={'sm'}>
            <Button variant={'subtle'}
                    fullWidth
                    onClick={() => changeLanguage('en')}
                    disabled={i18n.language === 'en'}>English</Button>
            <Button variant={'subtle'}
                    fullWidth
                    onClick={() => changeLanguage('it')}
                    disabled={i18n.language === 'it'}>Italiano</Button>
            <Button variant={'subtle'}
                    fullWidth
                    onClick={() => changeLanguage('pt')}
                    disabled={i18n.language === 'pt'}>Português</Button>
          </ButtonGroup>
          {!isMobile && <a className={classes.link}
                           onClick={(e) => {
                             e.preventDefault();
                             logout()
                           }}>
              <FontAwesomeIcon icon={faSignOutAlt} size={'xl'}
                               style={{ margin: 'auto' }} fixedWidth/>
          </a>}
        </div>
      </nav>
  );
}