import {
  Box,
  LoadingOverlay,
   SimpleGrid,
   Title
} from '@mantine/core';
import { useFetch } from "@mantine/hooks";
import { ICourse } from "../../interfaces/ICourse.tsx";
import { StatsRingCard } from "./StatsRingCard.tsx";


export function TableReviews() {
  const { data, loading } = useFetch<{ data: ICourse[] }>(
      `${import.meta.env.VITE_API_URL}/services/userCourses`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  return (
      <Box p="md" style={{position: "relative"}}>
        <Title order={4} mb={'md'}>Progresso corsi</Title>
        <LoadingOverlay visible={loading}/>
        <SimpleGrid   cols={{ base: 1, sm: 2, lg: 5 }}
                      spacing={{ base: 10, sm: 'xl' }}>
          {data?.data?.filter(r => r.unlocked).map((r) => <StatsRingCard key={r.id} course={r}/>)}
        </SimpleGrid>
      </Box>
  );
}