import {
  ActionIcon,
  Box,
  Fieldset,
  Flex,
  LoadingOverlay,
  Menu,
  Select,
  TagsInput,
  TextInput
} from "@mantine/core";
import { useFetch } from "@mantine/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useFormContext } from "../FormContext.tsx";
import { useTranslation } from "react-i18next";


export default function LifeSuiteStep() {
  const { t } = useTranslation();
  const form = useFormContext();

  const { data, loading } = useFetch<{ data: any }>(
      `${import.meta.env.VITE_API_URL}/services/userVideos`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  const addStep = (type: Record<string, any>) => {
    form.insertListItem('steps', { condition: '', ...type })
    console.log('Updated steps:', form.getValues().steps); // Debugging

  }


  return (
      <Box mt={'md'} mb={'md'} style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading}/>
        {form.getValues().steps.map((step: any, index: number) => (
            <Fieldset legend={`Step ${index}`} key={`s-${index}`}
                      style={{ position: 'relative' }}>

              {index > 0 &&
                  <TextInput label={t('choose_condition', "Si verifica se:")}
                             key={form.key(`steps.${index}.condition`)}
                             {...form.getInputProps(`steps.${index}.condition`)}
                  />
              }

              {step.video !== undefined &&
                  <Select
                      key={form.key(`steps.${index}.video`)}
                      {...form.getInputProps(`steps.${index}.video`)}
                      data={data?.data?.filter((v: any) => v.sub_category.toLowerCase().includes(form.getValues().type))
                          .map((v: any) => ({
                            value: v.id.toString(),
                            label: v.name
                          }))}
                      label={t('select_scenario', 'Seleziona lo scenario')}/>}


              {/* step.audio !== null &&
                    <Select
                        key={form.key(`steps.${index}.audio`)}
                        {...form.getInputProps(`steps.${index}.audio`)}
                        label={'Seleziona l\'audio'}/>*/}

              {step.question !== undefined && <>
                  <TextInput label={t('question', "Testo della domanda")}
                             key={form.key(`steps.${index}.question`)}
                             {...form.getInputProps(`steps.${index}.question`)}
                             placeholder={t('p_how_are_you', "Come ti senti?")}/>
                  <TagsInput
                      label={t('press_enter_to_confirm', "Premi Enter per confermare ogni stato")}
                      key={form.key(`steps.${index}.answers`)}
                      {...form.getInputProps(`steps.${index}.answers`)}
                      placeholder={t('p_example', "Male, così e così, Bene, Molto bene")}/>
              </>}

              <ActionIcon color={'red'}
                          size={'sm'}
                          onClick={() => form.removeListItem('steps', index)}
                          style={{ position: 'absolute', top: -4, right: 8 }}
                          variant={'subtle'}><FontAwesomeIcon
                  icon={faTrash}/></ActionIcon>

            </Fieldset>
        ))}
        <Flex justify={'center'} mt={'md'} mb={'md'}>
          <Menu position="right-end" offset={3} withArrow
                arrowPosition="center">
            <Menu.Target>
              <ActionIcon><FontAwesomeIcon icon={faPlus}/></ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={() => addStep({ audio: '' })} disabled>
                Audio
              </Menu.Item>
              <Menu.Item onClick={() => addStep({ video: '' })}>
                Video
              </Menu.Item>
              <Menu.Item onClick={() => addStep({ question: '', answers: [] })}>
                {t('question', 'Domanda')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>

        </Flex>
      </Box>
  )
}