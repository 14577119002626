import {
  Card,
  Image,
  RingProgress,
  Text,
  useMantineTheme
} from '@mantine/core';
import classes from './StatsRingCard.module.css';


export function StatsRingCard({course}: any) {
  const theme = useMantineTheme();



  return (
      <Card withBorder p="sm" radius="md" className={classes.card}>
        <div className={classes.inner}>
          <Image src={course.image} alt={course.name} w={140} h={140}/>

          <div className={classes.ring}>
            <RingProgress
                roundCaps
                thickness={6}
                size={150}
                sections={[{ value: course.progress_percentage ?? 0, color: theme.primaryColor }]}
                label={
                  <div>
                    <Text ta="center" fz="lg" className={classes.label}>
                      {(parseInt(String(Math.min(100, course.progress_percentage) ?? 0)))}%
                    </Text>
                    <Text ta="center" fz="xs" c="dimmed">
                      Completed
                    </Text>
                  </div>
                }
            />
          </div>
        </div>
      </Card>
  );
}