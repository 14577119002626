import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
    .use(HttpApi) // Loads translation files from the backend
    .use(initReactI18next)
    .init({
      lng: 'it',
      fallbackLng: 'it',
      ns: ['translation'],
      defaultNS: 'translation',
      backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      interpolation: {
        escapeValue: false,
      },
    });

export default i18n;
