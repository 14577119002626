import {
  Alert,
  Button,
  Flex,
  Group,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
  useMantineTheme
} from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../providers/AuthProvider.tsx";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { discipline } from "../data/discipline.tsx";
import { professions } from "../data/professions.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { regioni } from "../data/regioni.tsx";
import classes from './Welcome.module.css';
import { Trans, useTranslation } from "react-i18next";


export default function WelcomeModal() {

  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  const { authState, setAuthState } = useAuth();
  const [opened, { open, close }] = useDisclosure();
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [active, setActive] = useState(0);

  const form = useForm({
    mode: 'controlled',
    initialValues: {
      type: '',
      first_name: '',
      title: '',
      last_name: '',
      full_name: '',
      fiscal_code: '',
      role: '',
      contract: '',
      profession: 0,
      disciple: 0,

      order_of_psychologists: '',
      order_number: '',
    },
  });


  useEffect(() => {
    if ( !authState.user){
      console.log('no auth state user')
    }
    if(authState.user ){
      console.log('auth state user', authState.user)
      if(!authState.user.type){
        open();
      }
      form.setValues(authState.user);
    }
  }, [authState])

  const submit = (values: any) => {
    setInProgress(true);
    const filteredValues = Object.fromEntries(
        Object.entries(values)
            .filter(([_, value]) => value !== '' && value !== null) // Remove empty strings or null
            .flatMap(([key, value]) => {
              if ( typeof value === 'string' ) {
                value = value.trim(); // Trim string values
                if ( value === '' ) return []; // Exclude strings that become empty after trimming
              }
              if ( key === 'is_public' ) {
                // Cast boolean to TINYINT (0 or 1)
                return [[key, value ? 1 : 0]];
              }
              if ( typeof value === 'string' && (key === 'profession' || key === 'disciple') ) {
                // Cast to INT
                return [[key, parseInt(value)]];
              }
              return [[key, value]];
            })
    );
    console.log('Filtered values:', filteredValues);

    fetch(`${import.meta.env.VITE_API_URL}/services/me`, {
      method: "PATCH",
      body: JSON.stringify(filteredValues),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => {
          setAuthState({ loggedIn: true, user: data.data, loading: false });
          notifications.show({
            color: 'teal',
            withBorder: true,
            radius: 'lg',
            autoClose: false,
            message: 'Profilo aggiornato',
          })
          close();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false);
        });
  }

  const discipleData = useMemo(() => {
    const profession = form.getValues().profession;
    if ( profession && discipline[profession] ) {
      form.setValues({ disciple: 0 })
      return discipline[profession]
          .sort((s1: any, s2: any) => s1.label.toLowerCase().localeCompare(s2.label.toLowerCase()))
          .map((s: any) => ({
            label: s.label,
            value: s.value.toString(),
          }));
    }
    return [];
  }, [form.getValues().profession]); // Dependencies for memoization

  const accountType = form.getValues().type;

  const totalLen = accountType === 'professional' ? 3 : 2;

  const data = [
    {
      name: t('enthusiast', 'Entusiasta'),
      description: t('enthusiast_description', 'Sei interessat* a temi di salute mentale, rilassamento, gestione dello stress, videogame therapy etc.',),
      value: 'enthusiast'
    },
    {
      name: t('student', 'Studente'),
      description: t('student_description', 'Stai proseguendo un percorso di studi in medicina/psicologia etc.',),
      value: 'student'
    },
    {
      name: t('professional', 'Professionista'),
      description: t('professional_description', 'Un professionista della saluta mentale / medicina etc.',),
      value: 'professional'
    },
    {
      name: t('enterprise', 'Ente/Enterprise'),
      description: t('enterprise_description', 'Rappresenti un azienda/ente',),
      value: 'enterprise'
    }
  ]

  const cards = data.map((item) => (
      <Radio.Card className={classes.root} radius="md" value={item.value}
                  key={item.name}>
        <Group wrap="nowrap" align="flex-start">
          <Radio.Indicator/>
          <div>
            <Text className={classes.label}>{item.name}</Text>
            <Text className={classes.description}>{item.description}</Text>
          </div>
        </Group>
      </Radio.Card>
  ));


  return <Modal opened={opened} onClose={close} size={'xl'}
                closeOnEscape={false}
                withCloseButton={false}
                closeOnClickOutside={false}>
    <form onSubmit={form.onSubmit((values) => submit(values))}
          style={{ position: 'relative', padding: theme.spacing.sm }}>

      <Stepper active={active} onStepClick={setActive}
               style={{ minHeight: 450 }}
               orientation={width < (parseFloat(theme.breakpoints.lg) * 16) ? 'vertical' : 'horizontal'}>

        <Stepper.Step label="Account"
                      description={t('wm_descr', "Tipo di account")}>

          <RadioGroup  {...form.getInputProps('type')} mt={'lg'} required>
            <Stack pt="md" gap="xs">
              {cards}
            </Stack>
          </RadioGroup>

        </Stepper.Step>
        <Stepper.Step label="Info"
                      description={t('wm_info', "Informazioni generali")}>
          {accountType === 'enterprise' ? null :
              <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                <TextInput    {...form.getInputProps('first_name')}
                              placeholder={'Jón'}
                              required
                              label={t('wm_first_name', 'Nome')}/>
                <TextInput    {...form.getInputProps('last_name')}
                              placeholder={'Einarsson'}
                              required
                              label={t('wm_last_name', 'Cognome')}/>
              </Group>}

          <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
            <TextInput    {...form.getInputProps('fiscal_code')}
                          required
                          label={t('wm_fc', 'Codice Fiscale')}/>
            <TextInput    {...form.getInputProps('full_name')}
                          placeholder={'Aung San Suu Kyi'}
                          label={t('wm_full_name', 'Nome Completo')}/>
          </Group>

          {(accountType === 'professional' && i18n.language === 'it') && <>
              <Alert icon={<FontAwesomeIcon icon={faInfoCircle}/>}
                     variant="light"
                     color="blue" radius="md" mt={'xl'}>
                  <Trans i18nKey="wm_t1">
                      Se appartieni a un <b>Ordine di Psicologi</b>, completa
                      questi
                      campi
                      per poter accedere ai contenuti extra.
                  </Trans>
              </Alert>
              <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                  <Select data={regioni}
                          {...form.getInputProps('order_of_psychologists')}
                          label={'Ordine degli psicologi'}/>
                  <TextInput    {...form.getInputProps('order_number')}
                                label={'Numero di appartenenza'}/>
              </Group>
          </>}
        </Stepper.Step>
        {(accountType === 'professional' && i18n.language === 'it') &&
            <Stepper.Step label="ECM" description="Crediti Formativi">
                <Alert
                    mt={'md'}
                    mb={'xl'}
                    title='Compila questa sezione solo se hai necessità di ricevere crediti formativi'
                    icon={<FontAwesomeIcon icon={faInfoCircle}/>}>
                    <Text>
                        <Trans i18nKey="wm_t2">
                            Se non hai a portata di mano qualche dato e vuoi
                            completare/modificare questa sezione in un secondo
                            momento,
                            potrai ritrovarla nella pagina <b>Profilo</b>
                        </Trans>
                    </Text>
                </Alert>

                <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>

                    <Select data={[
                      { label: 'Partecipante', value: 'participant' },
                      { label: 'Docente', value: 'teacher' },
                      { label: 'Tutor', value: 'tutor' },
                      { label: 'Relatore', value: 'speaker' },
                    ]}
                            {...form.getInputProps('role')}
                            label={'Ruolo'}/>

                    <Select data={[
                      { label: 'Libero professionista', value: 'freelancer' },
                      {
                        label: 'Dipendente convenzionato',
                        value: 'contracted_employee'
                      },
                      { label: 'Nessuna occupazione', value: 'no_occupation' },
                    ]}
                            {...form.getInputProps('contract')}
                            label={'Tipo contratto'}/>

                    <TextInput    {...form.getInputProps('title')}
                                  placeholder={'Dott.'}
                                  label={'Titolo'}
                    />

                </Group>
                <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                    <Select searchable
                            data={professions
                                .sort((s1, s2) => s1.label.toLowerCase().localeCompare(s2.label.toLowerCase()))
                                .map(s => {
                                  return {
                                    label: s.label,
                                    value: s.value.toString()
                                  }
                                })}

                            {...form.getInputProps('profession')}
                            label={'Professione'}/>


                    <Select searchable
                            clearable
                            data={discipleData}
                            {...form.getInputProps('disciple')}
                            label={'Disciplina'}/>
                </Group>
            </Stepper.Step>
        }
        <Stepper.Completed>
          <Title mb='lg' mt='sm'
                 order={4}>{t('wm_next', 'Prossimi passi')}:</Title>
          <Stack>
            <Trans i18nKey="wm_t3">
              <Text>Dal menu laterale di sinistra potrai accedere alle varie
                sezioni dell'app.</Text>
              <Text>Se hai seguito qualcuno dei nostri corsi e hai bisogno
                di ricevere l'abilitazione all'uso di un'applicazione contatta
                il nostro supporto a support@become-hub.com.</Text>
              <Text>Puoi registrare in autonomia i tuoi dispositivi (visori di
                realtà virtuale come Meta Quest e sensori) dalla sezione
                Gestione Dispositivi</Text>
            </Trans>
          </Stack>
        </Stepper.Completed>
      </Stepper>

      <Flex justify={'flex-end'} mt={'md'} gap={'md'}>
        <Button disabled={inProgress || active === 0}
                variant={'outline'}
                onClick={() => setActive(Math.max(0, active - 1))}
        >{t('back', 'Indietro')}</Button>
        {active < totalLen ?
            <Button disabled={inProgress || form.getValues().type === ''}
                    onClick={() => setActive(active + 1)}
            >{t('next', 'Avanti')}</Button> : null}
        {active === totalLen ?
            <Button type={'submit'} disabled={inProgress}
                    loading={inProgress}>{t('start', 'Inizia')}</Button> :
            null
        }
      </Flex>
    </form>
  </Modal>
}