import {
  ActionIcon,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Loader,
  Text,
  Title,
  Tooltip,
  Transition,
  useMantineTheme
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faHeart,
  faHourglass,
  faLoader,
  faSignalBars,
  faSignalBarsSlash,
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useDisclosure, useFetch } from "@mantine/hooks";
import {
  CreateConfigurationModal
} from "../components/CreateConfiguration.modal.tsx";
import { useHeartRateStore } from "../store/useHeartRateStore.ts";
import { modals } from '@mantine/modals';
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faEye } from "@fortawesome/pro-light-svg-icons/faEye";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";

export function ActiveSessions() {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const { events, } = useHeartRateStore();
  const [loadingMyProducts, setLoadingMyProducts] = useState<boolean>(false)
  const [myProducts, setMyProducts] = useState<any>([])
  const [deleteInProgress, setDeleteInProgress] = useState<number>()
  const [mountedCards, setMountedCards] = useState<number[]>([]);
  const { data, loading, refetch } = useFetch<{ data: any[] }>(
      `${import.meta.env.VITE_API_URL}/services/configurations`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  );

  useEffect(() => {
    setLoadingMyProducts(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/myProducts`, {
      method: "GET",
      credentials: 'include'
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json();
        })
        .then((r) => setMyProducts(r.data))
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setLoadingMyProducts(false);
        });
  }, [])

  useEffect(() => {
    if ( data?.data ) {
      data.data.forEach((_, index) => {
        setTimeout(() => {
          setMountedCards((prev) => [...prev, index]);
        }, 50 * index); // Add a delay of 50ms per card
      });
    }
  }, [data]);


  const switchIcon = (status: string) => {
    console.log('switch icon')
    switch ( status ) {
      case 'queued':
        return <FontAwesomeIcon icon={faHourglass}
                                style={{ width: 40 }}
                                color={theme.colors.yellow[5]}/>
      case 'in-progress':
        return <FontAwesomeIcon icon={faCog}
                                style={{ width: 40 }}
                                spin color={theme.colors.blue[5]}/>
      case 'connecting':
        return <FontAwesomeIcon icon={faLoader} spin
                                style={{ width: 40 }}
                                color={theme.colors.orange[5]}/>
      case 'no-signal':
        return <FontAwesomeIcon icon={faSignalBarsSlash} fade
                                style={{ width: 40 }}
                                color={theme.colors.red[5]}/>
      default:
        return <FontAwesomeIcon icon={faSignalBars} fade
                                style={{ width: 40 }}
                                color={theme.colors.teal[5]}/>
    }
  }


  const confirmDelete = (id: number) => modals.openConfirmModal({
    title: t('pa_confirm_delete', 'Confermi di voler cancellare questa configurazione?'),
    children: (
        <Text size="sm">
          {t('pa_confirm_delete_txt', 'Se hai intenzione di cancellare la configurazione selezionata clicca conferma, altrimenti annulla per tornare indietro.')}
        </Text>
    ),
    labels: { confirm: t('confirm', 'Conferma'), cancel: t('cancel', 'Annulla') },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => deleteConfiguration(id),
  });

  const deleteConfiguration = (id: number) => {
    setDeleteInProgress(id)
    fetch(`${import.meta.env.VITE_API_URL}/services/configurations/${id}`, {
      method: "DELETE",
      credentials: 'include'
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setDeleteInProgress(undefined);
        });
  }


  console.log('Pro app rerender')
  return <>
    <Flex align="center" justify="space-between"
          mb={'sm'}
          direction={{ base: 'column-reverse', xl: 'row' }}>
        <Title>
          {t('pa_title', 'App 3D')}
        </Title>
    </Flex>
    <Divider mb={'md'}/>
    <Flex justify={'space-between'} mb={'md'} align={'center'} direction={{base: 'column', md: 'row'}} >
      <Title order={4}>{t('pa_sessions', 'Sessioni in coda')}</Title>
      <Group p={'xs'}>
        <Button
            size={'small'}
            variant={'light'}
            component={Link}
            to={'/completedSessions'}>{t('pa_history', 'Storico sessioni')}</Button>
        <Button onClick={open}>{t('pa_create_new', 'Crea una nuova sessione')}</Button>
      </Group>
    </Flex>
    <CreateConfigurationModal callback={refetch} opened={opened} close={close} myProducts={myProducts}/>

    {(loading || loadingMyProducts) && <Flex align={'center'}>
        <Loader/>
    </Flex>}

    {data?.data.length === 0 && <Text>{t('pa_no_sessions', 'Nessuna sessione trovata')}</Text>}
    {data?.data?.map((c: any, index: number) => {
          const event = c.status === 'in-progress' ? events
              .sort((a, b) => b.timestamp - a.timestamp)
              .find(e => e.deviceCode === c.deviceCode) : null
          return <Transition
              key={c.id}
              transition="scale-x"
              duration={500}
              mounted={mountedCards.includes(index)}
              timingFunction="ease"
          >
            {(styles) => (
                <Card mt='sm' shadow="sm"
                      style={styles}
                      padding="lg" radius="md" withBorder>
                  <Flex align={'center'} justify="space-between">
                    <Group>
                      <Tooltip label={c.status}>
                        {switchIcon(c.status)}
                      </Tooltip>
                      <Group>
                        <Text fw={700}>{t('pa_device', 'Dispositivo')}:</Text>
                        <Text fs="italic">{c.deviceName}</Text>
                        <Text>{c.deviceCode}</Text>
                        <Text fw={700}>{t('pa_user', 'Utente')}:</Text>
                        <Text fs="italic">{c.patientCode ?? 'n/a'}</Text>
                        <Text fw={700}>Scenario:</Text>
                        <Text fs="italic">{c.scenario}</Text>
                      </Group>
                      {(event) &&
                          <Text ml={12}>
                              <FontAwesomeIcon icon={faHeart}
                                               style={{ marginRight: 12 }}/>{event.value}
                          </Text>}
                    </Group>
                    <Group>

                      <ActionIcon
                                  onClick={() => navigate('/activeSessions/' + c.id)}><FontAwesomeIcon
                          icon={faEye}/></ActionIcon>

                      <ActionIcon color={'red'}
                                  loading={deleteInProgress === c.id}
                                  disabled={deleteInProgress === c.id}
                                  onClick={() => confirmDelete(c.id)}><FontAwesomeIcon
                          icon={faTrash}/></ActionIcon>
                    </Group>
                  </Flex>
                </Card>)}
          </Transition>
        }
    )
    }

  </>
}