export const discipline: any = {
    '1': [
        {label: 'Allergologia ed immunologia clinica', value: 1},
        {label: 'Angiologia', value: 2},
        {label: 'Cardiologia', value: 3},
        {label: 'Dermatologia e venereologia', value: 4},
        {label: 'Ematologia', value: 5},
        {label: 'Endocrinologia', value: 6},
        {label: 'Gastroenterologia', value: 7},
        {label: 'Genetica medica', value: 8},
        {label: 'Geriatria', value: 9},
        {label: 'Malattie metaboliche e diabetologia', value: 10},
        {label: 'Malattie dell\'apparato respiratorio', value: 11},
        {label: 'Malattie infettive', value: 12},
        {label: 'Medicina e chirurgia di accettazione e di urgenza', value: 13},
        {label: 'Medicina fisica e riabilitazione', value: 14},
        {label: 'Medicina interna', value: 15},
        {label: 'Medicina termale', value: 16},
        {label: 'Medicina aeronautica e spaziale', value: 17},
        {label: 'Medicina dello sport', value: 18},
        {label: 'Nefrologia', value: 19},
        {label: 'Neonatologia', value: 20},
        {label: 'Neurologia', value: 21},
        {label: 'Neuropsichiatria infantile', value: 22},
        {label: 'Oncologia', value: 23},
        {label: 'Pediatria', value: 24},
        {label: 'Psichiatria', value: 25},
        {label: 'Radioterapia', value: 26},
        {label: 'Reumatologia', value: 27},
        {label: 'Cardiochirurgia', value: 29},
        {label: 'Chirurgia generale', value: 30},
        {label: 'Chirurgia maxillo-facciale', value: 31},
        {label: 'Chirurgia pediatrica', value: 32},
        {label: 'Chirurgia plastica e ricostruttiva', value: 33},
        {label: 'Chirurgia toracica', value: 34},
        {label: 'Chirurgia vascolare', value: 35},
        {label: 'Ginecologia e ostetricia', value: 36},
        {label: 'Neurochirurgia', value: 37},
        {label: 'Oftalmologia', value: 38},
        {label: 'Ortopedia e traumatologia', value: 39},
        {label: 'Otorinolaringoiatria', value: 40},
        {label: 'Urologia', value: 41},
        {label: 'Anatomia patologica', value: 42},
        {label: 'Anestesia e rianimazione', value: 43},
        {label: 'Biochimica clinica', value: 44},
        {label: 'Farmacologia e tossicologia clinica', value: 45},
        {label: 'Laboratorio di genetica medica', value: 46},
        {label: 'Medicina trasfusionale', value: 47},
        {label: 'Medicina legale', value: 48},
        {label: 'Medicina nucleare', value: 49},
        {label: 'Microbiologia e virologia', value: 50},
        {label: 'Neurofisiopatologia', value: 51},
        {label: 'Neuroradiologia', value: 52},
        {label: 'Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)', value: 53},
        {label: 'Radiodiagnostica', value: 54},
        {label: 'Igiene, epidemiologia e sanità pubblica', value: 55},
        {label: 'Igiene degli alimenti e della nutrizione', value: 56},
        {label: 'Medicina del lavoro e sicurezza degli ambienti di lavoro', value: 57},
        {label: 'Medicina generale (medici di famiglia)', value: 58},
        {label: 'Continuità assistenziale', value: 59},
        {label: 'Pediatria (pediatri di libera scelta)', value: 60},
        {label: 'Scienza dell\'alimentazione e dietetica', value: 106},
        {label: 'Direzione medica di presidio ospedaliero', value: 107},
        {label: 'Organizzazione dei servizi sanitari di base', value: 108},
        {label: 'Audiologia e foniatria', value: 111},
        {label: 'Psicoterapia', value: 112},
        {label: 'Priv* di specializzazione *', value: 113}
    ],
    '2': [
        {label: 'Odontoiatria', value: 'Odontoiatria'}
    ],
    '3': [
        {label: 'Farmacia ospedaliera', value: 66},
        {label: 'Farmacia territoriale', value: 67}
    ],
    '4': [
        {label: 'Igiene degli allevamenti e delle produzioni zootecniche', value: 61},
        {label: 'Igiene prod., trasf., commercial., conserv.E tras.Alimenti di origine animale e derivati', value: 62},
        {label: 'Sanità animale', value: 63},
    ],
    '5': [
        {label: 'Psicoterapia', value: 77},
        {label: 'Psicologia', value: 78}
    ],

    '6': [
        {label: 'Biolog*', value: 68}
    ],
    '7': [
        {label: 'Chimica analitica', value: 76}
    ],
    '8': [
        {label: 'Fisica sanitaria', value: 79}
    ],
    '9': [
        {label: 'Assistente sanitario', value: 80}
    ],
    '10': [
        {label: 'Dietista', value: 81}
    ],
    '11': [
        {label: 'Educatore professionale', value: 83}
    ],
    '12': [
        {label: 'Fisioterapista', value: 82}
    ],
    '13': [
        {label: 'Igienista dentale', value: 84}
    ],
    '14': [
        {label: 'Infermiere', value: 85}
    ],
    '15': [
        {label: 'Infermiere pediatrico', value: 86}
    ],
    '16': [
        {label: 'Logopedista', value: 87}
    ],
    '17': [
        {label: 'Ortottista / assistente di oftalmologia', value: 88}
    ],
    '18': [
        {label: 'Ostetric*', value: 89}
    ],
    '19': [
        {label: 'Podolog*', value: 90}
    ],
    '20': [
        {label: 'Tecnic* audiometrista', value: 95}
    ],
    '21': [
        {label: 'Tecnic* audioprotesista', value: 96}
    ],
    '22': [
        {label: 'Tecnic* della fisiopatologia cardiocircolatoria e perfusione', value: 92}
    ],
    '23': [
        {label: 'Tecnic* della prevenzione nell\'ambiente e nei luoghi di lavoro', value: 105}
    ],
    '24': [
        {label: 'Tecnic* della riabilitazione psichiatrica', value: 91}
    ],
    '25': [
        {label: 'Tecnic* di neurofisiopatologia', value: 98}
    ],
    '26': [
        {label: 'Tecnic* ortopedico', value: 99}
    ],
    '27': [
        {label: 'Tecnic* sanitario di radiologia medica', value: 94}
    ],
    '28': [
        {label: 'Tecnic* sanitario laboratorio biomedico', value: 93}
    ],
    '29': [
        {label: 'Terapista della neuro e psicomotricità dell\'età evolutiva', value: 100}
    ],
    '30': [
        {label: 'Terapista occupazionale', value: 101}
    ]
}
