import {
  Accordion,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Title,
  Transition,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { professions } from "../data/professions.tsx";
import { discipline } from "../data/discipline.tsx";
import { useEffect, useMemo, useState } from "react";
import { regioni } from "../data/regioni.tsx";
import { notifications } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faDiploma,
  faNetworkWired,
  faStore
} from "@awesome.me/kit-528b119969/icons/duotone/solid";
import { useAuth } from "../providers/AuthProvider.tsx";
import { useTranslation } from "react-i18next";

export default function Profile() {

  const { t, i18n } = useTranslation();
  const { authState, setAuthState } = useAuth();
  const [mounted, setMounted] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState<boolean>(false)


  const form = useForm({
    mode: 'controlled',
    initialValues: {
      type: 'student',
      first_name: '',
      last_name: '',
      full_name: '',
      fiscal_code: '',
      vat_number: '',
      sdi_number: '',
      address: '',
      role: '',
      contract: '',
      title: '',
      profession: 0,
      disciple: 0,
      order_of_psychologists: '',
      order_number: '',
      profile_photo_path: '',
      region: '',
      is_public: true,
      website: '',
    },
  });


  useEffect(() => {
    if ( authState.user ) {
      setMounted(true)
      form.setValues(authState.user)
    }
  }, [authState.user])

  const discipleData = useMemo(() => {
    const profession = form.getValues().profession;
    if ( profession && discipline[profession] ) {
      form.setValues({ disciple: 0 })
      return discipline[profession]
          .sort((s1: any, s2: any) => s1.label.toLowerCase().localeCompare(s2.label.toLowerCase()))
          .map((s: any) => ({
            label: s.label,
            value: s.value.toString(),
          }));
    }
    return [];
  }, [form.getValues().profession]); // Dependencies for memoization


  const submit = (values: any) => {
    console.log('values', values)
    setInProgress(true);
    const filteredValues = Object.fromEntries(
        Object.entries(values)
            .filter(([_, value]) => value !== '' && value !== null) // Remove empty strings or null
            .flatMap(([key, value]) => {
              if ( typeof value === 'string' ) {
                value = value.trim(); // Trim string values
                if ( value === '' ) return []; // Exclude strings that become empty after trimming
              }
              if ( key === 'is_public' ) {
                // Cast boolean to TINYINT (0 or 1)
                return [[key, value ? 1 : 0]];
              }
              if ( typeof value === 'string' && (key === 'profession' || key === 'disciple') ) {
                // Cast to INT
                return [[key, parseInt(value)]];
              }
              return [[key, value]];
            })
    );
    console.log('Filtered values:', filteredValues);

    fetch(`${import.meta.env.VITE_API_URL}/services/me`, {
      method: "PATCH",
      body: JSON.stringify(filteredValues),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((data) => {
          console.log('response is', data)
          setAuthState({ loggedIn: true, user: data.data, loading: false });
          form.setValues(data.data)
          notifications.show({
            color: 'teal',
            withBorder: true,
            radius: 'lg',
            autoClose: false,
            message: 'Profilo aggiornato',
          })
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false);
        });
  }



  return <>
    <Title mb={'md'}>{t('user_profile', 'Profilo utente')}</Title>
    <Divider/>
    <form onSubmit={form.onSubmit((values) => submit(values))}
          style={{ position: 'relative' }}>
      <LoadingOverlay visible={inProgress}/>
      <Transition
          mounted={mounted}
          transition="scale-x"
          duration={800}
          timingFunction="ease"
      >
        {(styles) => <Box style={styles}>
          <Accordion defaultValue="-" variant="contained" radius="lg"
                     mt={'lg'}>
            <Accordion.Item value={"personal"}>
              <Accordion.Control icon={<FontAwesomeIcon icon={faCircleUser}/>}>
                {t('account_info', 'Informazioni Personali')}
              </Accordion.Control>
              <Accordion.Panel>

                <Group mb={'sm'}>
                  <Text size='sm'
                        fw={500}>{t('account_type', 'Tipologia di account:').replace('.', ':')} {form.getValues().type}</Text>
                  <Text size='sm'
                        c={'dimmed'}>{t('more_info_c', 'Contattaci se desideri cambiare tipologia di account: support@become-hub.com')}</Text>
                </Group>
                <SegmentedControl
                    mb={'md'}
                    {...form.getInputProps('type')}
                    readOnly
                    fullWidth size="md"
                    data={[
                      {
                        label: t('enthusiast', 'Entusiasta'),
                        value: 'enthusiast'
                      },
                      { label: t('student', 'Studente'), value: 'student' },
                      {
                        label: t('professional', 'Professionista'),
                        value: 'professional'
                      },
                      {
                        label: t('enterprise', 'Ente/Enterprise'),
                        value: 'enterprise'
                      },
                    ]}
                />

                <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                  <TextInput    {...form.getInputProps('first_name')}
                                label={t('first_name', 'Nome')}/>
                  <TextInput    {...form.getInputProps('last_name')}
                                label={t('last_name', 'Cognome')}/>
                </Group>

                <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                  <TextInput    {...form.getInputProps('fiscal_code')}
                                label={t('fiscal_code', 'Codice Fiscale')}/>
                  {i18n.language === 'it' && <>
                      <Select data={regioni}
                              {...form.getInputProps('order_of_psychologists')}
                              label={'Ordine degli psicologi'}/>
                      <TextInput    {...form.getInputProps('order_number')}
                                    label={'Numero di appartenenza'}/>
                  </>}
                </Group>

              </Accordion.Panel>
            </Accordion.Item>

            {i18n.language === 'it' && <>
                <Accordion.Item value={"ecm"}>
                    <Accordion.Control
                        icon={<FontAwesomeIcon icon={faDiploma}/>}>
                        Crediti formativi
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>

                            <Select data={[
                              { label: 'Partecipante', value: 'participant' },
                              { label: 'Docente', value: 'teacher' },
                              { label: 'Tutor', value: 'tutor' },
                              { label: 'Relatore', value: 'speaker' },
                            ]}
                                    {...form.getInputProps('role')}
                                    label={'Ruolo'}/>

                            <Select data={[
                              {
                                label: 'Libero professionista',
                                value: 'freelancer'
                              },
                              {
                                label: 'Dipendente convenzionato',
                                value: 'contracted_employee'
                              },
                              {
                                label: 'Nessuna occupazione',
                                value: 'no_occupation'
                              },
                            ]}
                                    {...form.getInputProps('contract')}
                                    label={'Tipo contratto'}/>

                            <TextInput    {...form.getInputProps('title')}
                                          placeholder={'Dott.'}
                                          label={'Titolo'}
                            />

                        </Group>
                        <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                            <Select searchable
                                    data={professions
                                        .sort((s1, s2) => s1.label.toLowerCase().localeCompare(s2.label.toLowerCase()))
                                        .map(s => {
                                          return {
                                            label: s.label,
                                            value: s.value.toString()
                                          }
                                        })}

                                    {...form.getInputProps('profession')}
                                    label={'Professione'}/>


                            <Select searchable
                                    data={discipleData}
                                    {...form.getInputProps('disciple')}
                                    label={'Disciplina'}/>
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>


                <Accordion.Item value={"network"}>
                    <Accordion.Control
                        icon={<FontAwesomeIcon icon={faNetworkWired}/>}>
                        Network Professionisti
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                            <Checkbox    {...form.getInputProps('is_public', { type: "checkbox" })}
                                         readOnly label={'Abilita'}/>
                            <TextInput    {...form.getInputProps('website')}
                                          label={'Sito Web'}/>
                            <TextInput    {...form.getInputProps('region')}
                                          label={'Regione'}/>
                            <TextInput    {...form.getInputProps('profile_photo_path')}
                                          label={'Url foto profilo'}/>
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={"billing"}>
                    <Accordion.Control
                        icon={<FontAwesomeIcon icon={faStore}/>}>
                        Dettagli pagamento
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Group wrap={'wrap'} grow mt={'md'} mb={'md'}>
                            <TextInput    {...form.getInputProps('address')}
                                          label={'Indirizzo di fatturazione'}/>
                            <TextInput    {...form.getInputProps('vat_number')}
                                          label={'P.IVA'}/>
                            <TextInput    {...form.getInputProps('sdi_number')}
                                          label={'SDI'}/>
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </>
            }
          </Accordion>
        </Box>}

      </Transition>


      <Flex justify={'flex-end'} mt={'md'}>
        <Button type={'submit'} disabled={inProgress}
                loading={inProgress}>Salva</Button>
      </Flex>
    </form>
  </>
}