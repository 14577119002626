import {
  Button,
  Group,
  Modal,
  Radio,
  Select,
  Stack,
  Stepper,
  Text,
  Title
} from "@mantine/core";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
import { DatePickerInput } from "@mantine/dates";

const checkData = [
  {
    name: 'Course',
    description: 'Any course',
  },
  {
    name: 'Subscription',
    description: 'Augmented Performance / Food Exposure / Video',
  }
];


export function AddProductModal({ userId, opened, close, callback }: any) {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [active, setActive] = useState(0);
  const MAX_STEP = 3;
  const nextStep = () => setActive((current) => Math.min(MAX_STEP, current + 1));
  const prevStep = () => setActive((current) => Math.max(0, current - 1));
  const [type, setType] = useState<string>('');

  const [products, setProducts] = useState<any[]>([]);
  const [userProducts, setUserProducts] = useState<any[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [value, setValue] = useState<Date | null>(null);

  const fetchData = () => {

    fetch(`${import.meta.env.VITE_API_URL}/services/products`, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json();
        })
        .then((r) => {
          setProducts(r.data.products)
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })

    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${userId}/products`, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json();
        })
        .then((r) => {
          setUserProducts(r.data)
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
  }

  useEffect(() => {
    if ( opened ) {
      fetchData();
    }
    return () => {
      setSelectedProduct(undefined)
      setActive(0)
    }
  }, [opened])

  useEffect(() => {
    if ( active === MAX_STEP ) {
      console.log('submit')
      switch ( type ) {
        case 'Course':
          assignProductToUser('courses')
          break;
        case 'Subscription':
          assignProductToUser('products')
          break;
        case 'Bundle':
          assignProductToUser('bundles')
          break;
      }

    }
  }, [active])


  const assignProductToUser = (endpoint: string) => {
    if ( !selectedProduct ) return;

    setInProgress(true)
    let validUntil = null;
    if(value){
      const newDate = new Date(value);
      newDate.setDate(newDate.getDate() + 1); // Add 1 day
      validUntil = newDate.toISOString().split('T')[0];
    }



    fetch(`${import.meta.env.VITE_API_URL}/services/admin/users/${userId}/${endpoint}`, {
      method: "POST",
      credentials: 'include',
      body: JSON.stringify({
        productId: parseInt(selectedProduct),
        validUntil
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setInProgress(false)
          callback();
        })
  }

  return <Modal opened={opened} onClose={close} title="Add product to user"
                size={'lg'} style={{ position: 'relative' }}>
    <Stepper active={active} onStepClick={setActive}>
      <Stepper.Step label="Type">
        <Radio.Group
            value={type}
            onChange={setType}
            label="Pick what you want to add"
        >
          <Stack pt="md" gap="xs">
            {
              checkData.map((item) => (
                  <Radio.Card radius="md"
                              p={'sm'}
                              value={item.name} key={item.name}>
                    <Group wrap="nowrap" align="flex-start">
                      <Radio.Indicator/>
                      <div>
                        <Title order={5}>{item.name}</Title>
                        <Text>{item.description}</Text>
                      </div>
                    </Group>
                  </Radio.Card>
              ))
            }
          </Stack>
        </Radio.Group>
      </Stepper.Step>
      <Stepper.Step label="Info">
        {type === 'Course' &&
            <Select
                value={selectedProduct}
                onChange={setSelectedProduct}
                searchable
                clearable
                data={
                  products?.filter((p: any) => {
                    const isCourse = p.category === 'course'
                    const owned = userProducts.some((up) => up.product_id === p.id)

                    return isCourse && !owned;
                  })
                      .map((p: any) => ({
                        value: p.id.toString(),
                        label: p.name
                      }))}/>
        }
        {type === 'Subscription' &&
            <Select
                value={selectedProduct}
                onChange={setSelectedProduct}
                searchable
                clearable
                data={
                  products?.filter((p: any) => {
                    const isCourse = p.category === 'experience'
                    const owned = userProducts.some((up) => up.product_id === p.id)

                    return isCourse && !owned;
                  })
                      .map((p: any) => ({
                        value: p.id.toString(),
                        label: p.name
                      }))}/>
        }
      </Stepper.Step>
      <Stepper.Step label="Extra actions" description="You can skip this part">
        <Stack>
          <DatePickerInput label={'Valid until'}
                           clearable
                           value={value}
                           onChange={setValue}/>
          <Select clearable searchable label={'Select email template'}
                  placeholder={'Dont send an email'}/>
        </Stack>
      </Stepper.Step>
      <Stepper.Completed>
        Completed, click back button to get to previous step
      </Stepper.Completed>
    </Stepper>

    <Group justify="center" mt="xl">
      <Button variant="default" onClick={prevStep}
              disabled={active === 0}>Back</Button>
      <Button
          disabled={(active === 0 && type === '') || inProgress}
          loading={inProgress}
          onClick={nextStep}>Next step</Button>
    </Group>
  </Modal>
}