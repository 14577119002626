import GenericListComponent from "./GenericListComponent.tsx";

export default function AdminProducts() {

  return (

      <GenericListComponent
          name={'product'}
          columns={[
            { accessor: 'id', title: 'ID' },
            { accessor: 'name', title: 'Name' },
            { accessor: 'image', title: 'Image' },
            { accessor: 'is_enabled', title: 'Active' },
          ]}
      />
  );
}