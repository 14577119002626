
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider.tsx";
import { Flex, Loader } from "@mantine/core";

const ProtectedRoute = ({ children }: any) => {
  const location = useLocation();
  const { authState } = useAuth();

  if (authState.loading) {
    return <Flex style={{minHeight: '100vh'}} justify={'center'} align={'center'}><Loader/></Flex>
  }

  if (!authState.loggedIn) {
    return <Navigate to={`/login${location.search}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
