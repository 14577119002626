import { create } from 'zustand';
import { useEffect } from "react";

// Define the shape of your events and store state
type Event = {
  id: string;
  deviceCode: string,
  timestamp: number;
  value: number;
};

interface HeartRateState {
  events: Event[];
  connectedDevices: any[],
  baseline: number;
  addEvent: (newEvent: Event) => void;
  setBaseline: (value: number) => void;
  loadFromLocalStorage: () => void;
  syncDevices: (devices: any) => void;
  clearEventsByDevice: (deviceCode: string) => void;
}

export const useSyncAcrossTabs = () => {
  const { loadFromLocalStorage } = useHeartRateStore();

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'heartRateData') {
        // Reload state from localStorage whenever the data changes
        loadFromLocalStorage();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [loadFromLocalStorage]);
};

export const useHeartRateStore = create<HeartRateState>((set, get) => ({
  events: [],
  connectedDevices: [],
  baseline: 0,

  addEvent: (newEvent: Event) => {
    const { events } = get();

    const alreadyExists = events.some(e => e.id === newEvent.id);
    if (!alreadyExists) {
      const updatedEvents = [...events, newEvent];
      set({ events: updatedEvents });
      saveToLocalStorage(updatedEvents, get().baseline);
    }
  },

  syncDevices: (newDevices: any) => {
    set({connectedDevices: newDevices})
  },

  setBaseline: (value: number) => {
    set({ baseline: value});
    saveToLocalStorage([], value);
  },

  loadFromLocalStorage: () => {
    const stored = getFromLocalStorage();
    if (stored) {
      set({ events: stored.events, baseline: stored.baseline });
    }
  },

  clearEventsByDevice: (deviceCode: string) => {
    const { events } = get();
    const filtered = events.filter(e => e.deviceCode !== deviceCode);
    set({ events: filtered });
    saveToLocalStorage(filtered, 0);
  },
}));

// Helpers for Local Storage
const STORAGE_KEY = 'heartRateData';

function saveToLocalStorage(events: Event[], baseline: number) {
  const data = JSON.stringify({ events, baseline });
  localStorage.setItem(STORAGE_KEY, data);
}

function getFromLocalStorage(): { events: Event[], baseline: number } | null {
  const data = localStorage.getItem(STORAGE_KEY);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}
