import {
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Radio,
  Stack,
  Textarea,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

export default function FeedbackComponent({
                                            c, opened, onClose, callback
                                          }: any) {

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      notes: '',
      relevance: '',
      quality: '',
      utility: '',
      time: '',
      sponsor: ''
    },
  });


  useEffect(() => {
    return () => {
      form.reset();
    }
  }, [opened])

  const submit = (values: any) => {
    console.log('submit values', values)
    setIsSubmitting(true)
    fetch(`${import.meta.env.VITE_API_URL}/services/submitFeedback/${c.id}`, {
      method: "POST",
      body: JSON.stringify({
        review: values
      }),
      credentials: 'include',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          console.info('saving')
          if ( !response.ok ) {
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          notifications.show({
            color: 'teal',
            withBorder: true,
            radius: 'lg',
            autoClose: false,
            message: 'Questionario di gradimento inviato',
          })
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
              setIsSubmitting(false)
              callback();
              onClose();
            }
        )
  }

  return <Modal opened={opened} onClose={onClose}
                title={'Questionario di gradimento'}
                fullScreen>


    <form onSubmit={form.onSubmit((values) => submit(values))}
          style={{ position: 'relative' }}>
      <LoadingOverlay visible={isSubmitting}/>
      <Stack>
        <Radio.Group
            {...form.getInputProps('relevance')}
            label="Come valuta la rilevanza degli argomenti trattati rispetto alle sue necessità di aggiornamento?"
        >
          <Radio value="1" label="Non rilevante" mt={5} mb={5}/>
          <Radio value="2" label="Poco rilevante" mt={5} mb={5}/>
          <Radio value="3" label="Rilevante" mt={5} mb={5}/>
          <Radio value="4" label="Più che rilevante" mt={5} mb={5}/>
          <Radio value="5" label="Molto rilevante" mt={5} mb={5}/>
        </Radio.Group>

        <Radio.Group
            {...form.getInputProps('quality')}
            label="Come valuta la qualità educativa del programma ECM?"
        >
          <Radio value="1" label="Insufficiente" mt={5} mb={5}/>
          <Radio value="2" label="Parziale" mt={5} mb={5}/>
          <Radio value="3" label="Sufficiente" mt={5} mb={5}/>
          <Radio value="4" label="Buono" mt={5} mb={5}/>
          <Radio value="5" label="Eccellente" mt={5} mb={5}/>
        </Radio.Group>

        <Radio.Group
            {...form.getInputProps('utility')}
            label="Come valuta l’utilità di questo evento per la sua formazione/aggiornamento?"
        >
          <Radio value="1" label="Insufficiente" mt={5} mb={5}/>
          <Radio value="2" label="Parziale" mt={5} mb={5}/>
          <Radio value="3" label="Utile" mt={5} mb={5}/>
          <Radio value="4" label="Più che utile" mt={5} mb={5}/>
          <Radio value="5" label="Molto utile" mt={5} mb={5}/>
        </Radio.Group>

        <Radio.Group
            {...form.getInputProps('time')}
            label="Il tempo che ha dedicato ad acquisire le informazioni contenute nel programma FAD rispetto alle ore previste è stato:"
        >
          <Radio value="1" label="Molto inferiore" mt={5} mb={5}/>
          <Radio value="2" label="Poco inferiore" mt={5} mb={5}/>
          <Radio value="3" label="Uguale" mt={5} mb={5}/>
          <Radio value="4" label="Poco superiore" mt={5} mb={5}/>
          <Radio value="5" label="Molto superiore" mt={5} mb={5}/>
        </Radio.Group>

        <Radio.Group
            {...form.getInputProps('sponsor')}
            label={"Questo programma FAD NON è stato preparato con il supporto di uno sponsor. Ritiene che nel programma ci siano riferimenti, indicazioni e/o informazioni non equilibrate o non corrette per influenza di sponsor o altri interessi commerciali ?"}
        >
          <Radio value="1" label="Nessuna influenza" mt={5} mb={5}/>
          <Radio value="2" label="Influenza poco rilevante" mt={5} mb={5}/>
          <Radio value="3" label="Influenza rilevante" mt={5} mb={5}/>
          <Radio value="4" label="Influenza più che rilevante" mt={5} mb={5}/>
          <Radio value="5" label="Influenza molto rilevante" mt={5} mb={5}/>
        </Radio.Group>


        <Textarea
            maxLength={500}
            description={`${form.getValues().notes.length} / 500 caratteri`}
            label={'In caso di risposte di influenza “più che rilevante” o “molto rilevante” indicare qualche esempio.\n'}
            {...form.getInputProps('notes')}
            autosize
            minRows={2}
        />
      </Stack>
      <Flex justify={'flex-end'} mt={'md'}>
        <Button type={'submit'} disabled={isSubmitting}
                loading={isSubmitting}>Invia</Button>
      </Flex>
    </form>


  </Modal>
}