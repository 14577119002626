import { Avatar, Group, LoadingOverlay, Text } from '@mantine/core';
import classes from './UserInfo.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone
} from "@awesome.me/kit-528b119969/icons/duotone/solid";

export function UserInfo({info, loadingUserInfo}: any) {
  return (
      <div>
        <Group wrap="nowrap" style={{position: 'relative'}}>
          <LoadingOverlay visible={loadingUserInfo}/>
          <Avatar
              src={info?.profile_photo_path ?? "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png"}
              size={94}
              radius="md"
          />
          <div>
            <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
              {info?.type}
            </Text>

            <Text fz="lg" fw={500} className={classes.name}>
              {info?.first_name} {info?.last_name} | {info?.full_name}
            </Text>

            <Group wrap="nowrap" gap={10} mt={3}>
              <FontAwesomeIcon icon={faEnvelope}/>
              <Text fz="xs" c="dimmed">
                {info?.email}
              </Text>
            </Group>

            <Group wrap="nowrap" gap={10} mt={5}>
              <FontAwesomeIcon icon={faPhone}/>
              <Text fz="xs" c="dimmed">
                {info?.phone}
              </Text>
            </Group>
          </div>
        </Group>
      </div>
  );
}