import { LoadingOverlay, Modal, useMantineTheme } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { notifications } from "@mantine/notifications";

export default function VideoComponent({
                                         c,
                                         currentChapter,
                                         setCurrentChapter,
                                         callback
                                       }: any) {

  const theme = useMantineTheme();
  const [loading, setLoading] = useState<boolean>(true)
  const videoRef = useRef<HTMLVideoElement | null>(null);
  let lastReportedTime = c.current_time ?? 0;
  let isCompleted = false;

  useEffect(() => {
    const video = videoRef.current;
    if ( !video ) {
      console.error('Video element is not available.');
      return;
    }
    if ( currentChapter.order < c.current_section ) {
      setLoading(false)
      return;
    }

    video.currentTime = lastReportedTime;
    setLoading(false)

    setInterval(() => {
      if ( video.paused ) return;
      if ( video.currentTime < lastReportedTime ) return;
      if ( !c.ecm ) return;
      console.info('update current step', lastReportedTime, 'course id', c.id, 'section', c.current_section)

      fetch(`${import.meta.env.VITE_API_URL}/services/userCourses/${c.id}`, {
        method: "POST",
        body: JSON.stringify({
          currentSection: c.current_section,
          currentTime: lastReportedTime
        }),
        credentials: 'include',
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
          .then((response) => {
            console.info('saving')
            if ( !response.ok ) {
              setCurrentChapter(undefined)
              callback()
              return response.text().then((text) => {
                throw new Error(`HTTP ${response.status}: ${text}`);
              });
            }
          })
          .catch((error) => {
            console.error('error', error, error.message);
            notifications.show({
              color: 'red',
              withBorder: true,
              radius: 'lg',
              title: 'An error occurred',
              autoClose: false,
              message: error.message,
            })
          })
    }, 15000)


    function onSeeking() {
      if ( video && video.currentTime > lastReportedTime ) {
        video.currentTime = lastReportedTime;
      }
    }

    function onTimeUpdate() {
      if ( !isCompleted && video ) {
        const currentTime = Math.floor(video.currentTime);
        if ( currentTime > lastReportedTime ) {
          if ( currentTime < lastReportedTime + 3 ) {
            lastReportedTime = currentTime;
          } else {
            video.currentTime = lastReportedTime;
          }
        }
      }
    }

    function onEnded() {
      setLoading(true)
      isCompleted = true;
      fetch(`${import.meta.env.VITE_API_URL}/services/userCourses/${c.id}`, {
        method: "POST",
        body: JSON.stringify({
          currentSection: c.current_section + 1,
          currentTime: lastReportedTime
        }),
        credentials: 'include',
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
          .then((response) => {
            console.info('saving')
            if ( !response.ok ) {
              return response.text().then((text) => {
                throw new Error(`HTTP ${response.status}: ${text}`);
              });
            }
            setCurrentChapter(undefined)
            callback()
          })
          .catch((error) => {
            console.error('error', error, error.message);
            notifications.show({
              color: 'red',
              withBorder: true,
              radius: 'lg',
              title: 'An error occurred',
              autoClose: false,
              message: error.message,
            })
          })
          .finally(() => {
            setLoading(false)
          });
    }

    function rateChange(){
      if ( video && video.playbackRate !== 1 ) {
        video.playbackRate = 1;
      }
    }

    function volumeChanged(){
      console.log('video volume is', video?.volume)
      if ( video && video.volume < 0.2 ) {
        video.volume = 0.2; // Enforce minimum volume (20%)
      }
      if(video && video.muted){
        video.muted = false;
      }
    }


    if ( c.ecm ) {
      video.addEventListener('seeking', onSeeking);
      video.addEventListener('timeupdate', onTimeUpdate);
      video.addEventListener('ended', onEnded);
      video.addEventListener('ratechange', rateChange);
      video.addEventListener('volumechange', volumeChanged);
    }
    // Cleanup event listeners
    return () => {
      if ( c.ecm ) {
        video.removeEventListener('seeking', onSeeking);
        video.removeEventListener('timeupdate', onTimeUpdate);
        video.removeEventListener('ended', onEnded);
        video.removeEventListener('ratechange', rateChange);
        video.removeEventListener('volumechange', volumeChanged);
      }
    };

  }, [videoRef.current]);

  return <Modal opened={currentChapter}
                size={'xl'}
                title={currentChapter.title}
                onClose={() => {
                  setCurrentChapter(undefined)
                }}>
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={loading}/>
      <video width="100%" height="auto"
             disablePictureInPicture
             controlsList="nodownload noseek"
             controls
             style={{
               borderRadius: 6,
               borderWidth: 1,
               borderStyle: "solid",
               borderColor: theme.colors.gray[5],
               outline: "none",
               boxShadow: "none"
             }}
             ref={videoRef}>
        <source src={currentChapter.video_url}
                type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  </Modal>
}