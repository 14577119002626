import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Container,
  Paper,
  SegmentedControl,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from "@mantine/form";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
      termsOfService: false,
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : t('invalid_email', 'Email non valida')),
      termsOfService: (value: boolean) => value ? null : t('validate_terms', 'Per registrare un account è necessario accettare i termini e le condizioni')
    },
  });

  const onSubmit = (values: any) => {
    setLoggingIn(true);
    fetch(`${import.meta.env.VITE_API_URL}/auth/register`, {
      method: "POST",
      body: JSON.stringify({
        email: values.email,
        from: window.location.origin,
        params: window.location.search,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
        .then((response) => {
          if ( !response.ok ) {
            // Throw an error for HTTP errors
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status}: ${text}`);
            });
          }
          return response.json()
        })
        .then((r) => {
          notifications.show({
            color: 'teal',
            withBorder: true,
            radius: 'lg',
            title: t('registered', 'Registrazione avvenuta'),
            autoClose: false,
            message: r.message,
          })
        })
        .catch((error) => {
          console.error('error', error, error.message);
          notifications.show({
            color: 'red',
            withBorder: true,
            radius: 'lg',
            title: 'An error occurred',
            autoClose: false,
            message: error.message,
          })
        })
        .finally(() => {
          setLoggingIn(false);
        });
  }

  return (<>
        <Container size={420} my={40}>
          <Title ta="center">
            {t('register_title', 'Benvenuta/o in BECOME.')}
          </Title>
          <SegmentedControl
              mt={'md'}
              value={'register'}
              fullWidth
              onChange={() => navigate('/login')}
              data={[
                { label: t('login', 'Login'), value: 'login' },
                {
                  label: t('create_account', 'Crea account'),
                  value: 'register'
                }
              ]}
          />

          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <form
                onSubmit={form.onSubmit(onSubmit)}>
              <Box style={{ position: 'relative' }}>
                <TextInput label="Email"
                           disabled={loggingIn}
                           {...form.getInputProps('email')}
                           placeholder="m.rossi@libero.it" required/>
                <Checkbox mt='md'
                          disabled={loggingIn}
                          label={
                            <Text mt={-3}>
                              <Trans i18nKey="terms_and_conditions">
                                Ho letto e accetto
                                <Anchor
                                    href={'https://discoverbecome.com/it/eula'}
                                    target={'_blank'} ml={'xs'} mr={'xs'}>
                                  i termini e le condizioni
                                </Anchor>
                                e
                                <Anchor
                                    href={'https://discoverbecome.com/it/privacy'}
                                    target={'_blank'} ml={'xs'} mr={'xs'}>l'informativa
                                  privacy</Anchor>
                              </Trans>
                            </Text>
                          }
                          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
                          required/>
              </Box>
              <Button
                  loading={loggingIn}
                  disabled={loggingIn}
                  fullWidth mt="xl" type="submit">
                {t('create_account', 'Crea account')}
              </Button>
            </form>
          </Paper>
        </Container>
      </>
  )
}